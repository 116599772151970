import useSWR from 'swr'
import { FetchResult, Project } from '../entities';

type Result = FetchResult & { project: Project}

export function useProject(id: string) : Result {
    const { data, error, mutate } = useSWR(`/projects/${id}`);

    return {
      project: data?.data,
      isLoading: !error && !data,
      isError: error,
      mutate
    }
}