import useSWR from 'swr';
import { FetchResult, TaskPhase } from '../entities';

type Result = FetchResult & { data: TaskPhase[] };

export function useProjectPhases(id?: string | null): Result {
  const { data, error, mutate } = useSWR(id ? `/tasks-phases/${id}/project` : null);
  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
