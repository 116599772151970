import { Button, Input, message, Space, Table } from 'antd';
import { useState } from 'react';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

type ArtifialTask = { name: string };

interface Props {
  tasks: string[];
  onChange: (ts: string[]) => void;
  displayOptions: boolean;
}

const PhaseTasks = ({ tasks, onChange, displayOptions }: Props) => {
  const [newTaskName, setNewTaskName] = useState<string>('');

  const addTask = () => {
    const checkIfExists = tasks.filter(t => t === newTaskName).length > 0;
    if (checkIfExists) {
      message.warning('Task with that name already exists');
    } else {
      onChange([...tasks, newTaskName]);
      setNewTaskName('');
    }
  };

  const deleteTask = (name: string) => {
    onChange(tasks.filter(t => t !== name));
  };

  const move = (name: string, up: boolean) => {
    const newTasks = [...tasks];
    const fromIndex = newTasks.indexOf(name);
    const toIndex = up ? fromIndex - 1 : fromIndex + 1;

    const element = newTasks.splice(fromIndex, 1)[0];
    newTasks.splice(toIndex, 0, element);
    onChange(newTasks);
  };

  const columns = [
    {
      dataIndex: 'name',
      title: 'Task',
    },
    displayOptions
      ? {
        dataIndex: 'moveUp',
        width: 50,
        render: (value: any, record: ArtifialTask) => (
          <Space>
            {tasks[0] !== record.name && (
              <Button icon={<ArrowUpOutlined />} size="small" onClick={() => move(record.name, true)}>
                Move up
              </Button>
            )}
          </Space>
        ),
      }
      : {},
    displayOptions
      ? {
        dataIndex: 'moveDown',
        width: 50,
        render: (value: any, record: ArtifialTask) => (
          <Space>
            {tasks[tasks.length - 1] !== record.name && (
              <Button icon={<ArrowDownOutlined />} size="small" onClick={() => move(record.name, false)}>
                Move down
              </Button>
            )}
          </Space>
        ),
      }
      : {},
    displayOptions
      ? {
        dataIndex: 'delete',
        width: 50,
        render: (value: any, record: ArtifialTask) => (
          <Space>
            <Button danger size="small" onClick={() => deleteTask(record.name)}>
              Delete
            </Button>
          </Space>
        ),
      }
      : {},
  ];

  return (
    <Table
      dataSource={tasks.map(t => ({ name: t }))}
      columns={columns}
      size="small"
      className="task-table"
      rowKey={t => t.name}
      pagination={false}
      summary={() =>
        displayOptions ? (
          <Input.Group compact style={{ marginTop: 10 }} size="small">
            <Input style={{ width: 250 }} value={newTaskName} onChange={e => setNewTaskName(e.target.value)} placeholder="New task" />
            <Button type="primary" size="small" onClick={addTask}>
              Add
            </Button>
          </Input.Group>
        ) : null
      }
    />
  );
};

export default PhaseTasks;
