import { Divider, message } from 'antd';
import axios from 'axios';
import { User } from '../../entities';
import UserPositionsSelector from './UserPositionsSelector';

interface Props {
  user: User;
}

const UserPositions = ({ user }: Props) => {
  const handleChange = async (positions: string[]) => {
    await axios.patch(`/users/${user.id}`, { positions });
    message.success('Saved');
  };

  return (
    <>
      <Divider orientation="left">User roles in company</Divider>
      <UserPositionsSelector onChange={handleChange} defaultValue={user.positions} style={{ width: '400px' }} />
    </>
  );
};

export default UserPositions;
