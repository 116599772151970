import useSWR from 'swr';
import { FetchResult, TaskPhase, Task } from '../entities';

type Result = FetchResult & { phases: TaskPhase[]; unassigned: Task[] };

export function useProjectTasks(id?: string | null): Result {
  const { data, error, mutate } = useSWR(id ? `/tasks/${id}/project` : null);
  return {
    phases: data?.data.phases,
    unassigned: data?.data.unassigned,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
