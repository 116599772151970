import axios from 'axios';
import { Button, Spin, Table, PageHeader, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Layout from '../../layouts/authorised/Authorised';
import { usePresentations } from '../../dal';
import { Presentation } from '../../entities';
import { useState } from 'react';
import { formatDate, nullableDataSorter } from '../../common/utils';
import AddPresentationForm from '../../components/presentation/AddPresentationForm';
import { useNavigate } from 'react-router-dom';

const Presentations = () => {
  const presentationsURL = process.env.REACT_APP_VIRTUAL_PRESENTATIONS_BASE_URL || '';

  const navigate = useNavigate();
  const { presentations = [], mutate } = usePresentations();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  // TODO: Search
  const [phrase] = useState<string>('');
  const searchByPhrase = (presentations: Presentation[], phrase: string): Presentation[] => {
    return presentations.filter(p => p.key.toLowerCase().search(phrase.toLowerCase()) > -1);
  };

  const onDelete = async (id: string) => {
    await axios.delete(`/presentations/${id}`);
    await mutate();
  };

  const onClone = async (id: string) => {
    await axios.put(`/presentations/${id}`);
    await mutate();
  };

  if (!presentations)
    return (
      <Layout>
        <Spin />
      </Layout>
    );

  return (
    <Layout>
      <PageHeader
        title="Presentations"
        extra={[
          <Button icon={<PlusOutlined />} type="primary" onClick={() => setDrawerOpen(true)}>
            Create new
          </Button>,
        ]}
      />
      <Table
        className="clickable-row"
        dataSource={searchByPhrase(presentations, phrase)}
        size="small"
        rowKey="id"
        pagination={false}
        onRow={record => {
          return {
            onClick: () => {
              navigate(`/presentations/${record.id}`);
            },
          };
        }}
      >
        <Table.Column
          key="key"
          dataIndex="key"
          title="URL / Preview"
          render={(value, row: any) => (
            <a href={(row.login || row.password ? presentationsURL.replace('://', '://' + row.login + ':' + row.password + '@') : presentationsURL) + row.key} onClick={event => event.stopPropagation()} target="_blank" rel="noreferrer">
              {value}
            </a>
          )}
          sorter={(a: Presentation, b: Presentation) => nullableDataSorter(a.key, b.key)}
        />
        <Table.Column key="name" dataIndex="name" title="Name" sorter={(a: Presentation, b: Presentation) => nullableDataSorter(a.key, b.key)} />
        <Table.Column
          key="templateName"
          dataIndex="templateName"
          title="Template"
          sorter={(a: Presentation, b: Presentation) => nullableDataSorter(a.key, b.key)}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Created"
          render={value => formatDate(value)}
          sorter={(a: Presentation, b: Presentation) =>
            nullableDataSorter(a.createdAt ? new Date(a.createdAt) : null, b.createdAt ? new Date(b.createdAt) : null)
          }
        />
        <Table.Column
          dataIndex="updatedAt"
          title="Updated"
          render={value => formatDate(value)}
          sorter={(a: Presentation, b: Presentation) =>
            nullableDataSorter(a.updatedAt ? new Date(a.updatedAt) : null, b.updatedAt ? new Date(b.updatedAt) : null)
          }
        />
        <Table.Column
          key="action"
          render={(_, row: Presentation) => (
            <>
              <Button
                type="link"
                onClick={e => {
                  onClone(row.id);
                  e.stopPropagation();
                }}
              >
                Make a copy
              </Button>
              <Popconfirm
                title="Are you sure to delete this presentation?"
                onConfirm={e => {
                  onDelete(row.id);
                  e?.stopPropagation();
                }}
                onCancel={e => {
                  e?.stopPropagation();
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="link"
                  danger
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  Delete
                </Button>
              </Popconfirm>
            </>
          )}
        />
      </Table>
      {drawerOpen && <AddPresentationForm modalOpen={drawerOpen} setModalOpen={setDrawerOpen} />}
    </Layout>
  );
};

export default Presentations;
