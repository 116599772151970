import { useTasks } from '../../dal';
import { useProjectTasks } from '../../dal/useProjectTasks';
import { useTicketTasks } from '../../dal/useTicketTasks';
import { useUserTasks } from '../../dal/useUserTasks';
import { Task } from '../../entities';

type MutatationData = Task[] | { myTasks: Task[]; projectsTasks: Task[] };
type Params = { userId?: number; projectId?: string | null; ticketId?: string };

export const useMutateTasks = ({ userId, projectId, ticketId }: Params) => {
  const { mutate } = useTasks();
  const { mutate: mutateUserTasks } = useUserTasks(userId);
  const { mutate: mutateProjectTasks } = useProjectTasks(projectId);
  const { mutate: mutateTicketTasks } = useTicketTasks(ticketId);

  const mutateTasks = async (data: MutatationData) => {
    if (ticketId) {
      await mutateTicketTasks(data, false);
    }

    if (userId) {
      await mutateUserTasks(data, false);
    } else if (projectId) {
      await mutateProjectTasks(data, false);
    } else {
      await mutate(data, false);
    }
  };

  return mutateTasks;
};
