import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { useFiles } from '../../dal/useFiles';
import * as Sentry from '@sentry/browser';

const { Dragger } = Upload;

const Uploader = () => {
  const { mutate } = useFiles();
  const onChange = (info: UploadChangeParam<UploadFile>) => {
    const { status } = info.file;

    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
      mutate();
    } else if (status === 'error') {
      Sentry.captureException(info);

      if (info.file.response.message === 'File too large') {
        message.error(`${info.file.name} upload failed - file is to large. Max 3MB possible.`);
      } else {
        message.error(`${info.file.name} file upload failed - unknown error.`);
      }
    }
  };

  return (
    <Dragger name="file" multiple showUploadList={false} action={process.env.REACT_APP_UPLOAD_URL} onChange={onChange}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">Support for a single or bulk upload.</p>
    </Dragger>
  );
};

export default Uploader;
