import { Spin, Timeline } from 'antd';
import moment from 'moment';
import { useTaskHistory } from '../../dal/useTaskHistory';
import { TaskHistory as TaskHistoryType } from '../../entities';
import './TaskHistory.css';

function getTaskHistoryDescription(entry: TaskHistoryType): string {
  if (entry.changeType === 'created') {
    return `Task created by ${entry.changedBy.name}.`;
  }
  if (entry.changeType === 'dueDate') {
    return `Due date changed to ${moment(entry.newValue).format('ll')} by ${entry.changedBy.name}.`;
  }
  if (entry.changeType === 'status') {
    return `Marked as ${entry.newValue === 'done' ? 'finished' : 'unfinished'} by ${entry.changedBy.name}.`;
  }
  if (entry.changeType === 'state') {
    return `Status changed to "${entry.newValue}" by ${entry.changedBy.name}.`;
  }
  if (entry.changeType === 'phase') {
    return `Phase changed by ${entry.changedBy.name}.`;
  }

  return 'Unknown action.';
}
interface Props {
  id: string;
}

const TaskHistory = ({ id }: Props) => {
  const { data } = useTaskHistory(id);

  if (!data) return <Spin />;

  return (
    <Timeline mode="left">
      {data.map(e => (
        <Timeline.Item label={moment(e.createdAt).format('lll')} key={e.id}>
          {getTaskHistoryDescription(e)}
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export default TaskHistory;
