import { Button, message, PageHeader, Space, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import HomepageEditor from '../components/settings/HomepageEditor';
import { useFiles } from '../dal/useFiles';
import { copyToClipboard } from './filesManager';

const Settings = () => {
  const { mutate } = useFiles();

  return (
    <div className='flex flex-col h-full'>
      <PageHeader
        title="Homepage settings"
        extra={
          <Upload
            fileList={undefined}
            itemRender={() => null}
            name="file"
            action={process.env.REACT_APP_UPLOAD_URL}
            onChange={info => {
              if (info.file.status === 'done') {
                message.success(`File uploaded.`, 2000);
                copyToClipboard(info.file.response.file);
                mutate();
              } else if (info.file.status === 'error') {
                message.error(`File upload failed.`);
              }
            }}
          >
            <Button icon={<InboxOutlined />} type="primary" className='button-figma-desing'>
              Upload file
            </Button>
          </Upload>
        }
      />
      <Space direction="vertical" style={{ width: '100%' }} size={20}>
        <HomepageEditor />
      </Space>
    </div>
  );
};

export default Settings;
