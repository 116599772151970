import { Button, Form, Input } from 'antd';

type Props = {
  onFinish: (values: { email: string; password: string }) => void;
};

const EmailPasswordForm = (props: Props) => {
  return (
    <Form layout="vertical" onFinish={props.onFinish} autoComplete="off" validateTrigger="onSubmit">
      <Form.Item name="email" rules={[{ required: true, type: 'email', message: 'Please input correct email.' }]}>
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item name="password" rules={[{ required: true, message: 'Please input your password.' }]}>
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EmailPasswordForm;
