import { useState } from 'react';
import { Typography, Button, PageHeader, Spin, Table } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useSalesReport } from '../../dal/useSalesReport';
import { formatCurrency, formatDate } from '../../common/utils';
import { SalesReportItem } from '../../entities';

const SalesReport = () => {
  const [currentMonth, setCurrentMonth] = useState<string | null>(null);
  const { report, pagination, totalYear, totalMonth } = useSalesReport(currentMonth);

  const Summary = () => {
    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} align="right" colSpan={4}>
            Month Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align="right">
            <Typography.Text>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalMonth || 0)}</Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} align="right" colSpan={4}>
            Year Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align="right">
            <Typography.Text>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalYear || 0)}</Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  if (!report)
    return (
      <div>
        <Spin />
      </div>
    );

  return (
    <div className='flex flex-col h-full'>
      <PageHeader
        title="Sales Report"
        subTitle={moment(pagination?.current).format('MMMM YYYY')}
        extra={[
          <Button icon={<ArrowLeftOutlined />} onClick={() => setCurrentMonth(pagination.prev)} />,
          <Button icon={<ArrowRightOutlined />} onClick={() => setCurrentMonth(pagination.next)} />,
        ]}
      />

      <div className="ant-space-vertical">
        <Table bordered size="small" dataSource={report} summary={Summary} pagination={false}>
          <Table.Column dataIndex="client" title="Client" render={(_, i: SalesReportItem) => `${i.clientCode} ${i.client}`} />
          <Table.Column dataIndex="projectCode" title="Code" />
          <Table.Column dataIndex="project" title="Project" />
          <Table.Column dataIndex="contractSignedDate" title="Contract Signed Date" render={value => formatDate(value)} />
          <Table.Column dataIndex="budget" title="Budget" align="right" render={formatCurrency} />
        </Table>
      </div>
    </div>
  );
};

export default SalesReport;
