import useSWR from 'swr';
import { FetchResult } from '../entities';

type Result<T> = FetchResult & { settings?: T; key: string };

export function useSettings<T = any>(key?: string): Result<T> {
  const { data, error, mutate } = useSWR(key ? `/settings/${key}` : null);

  return {
    settings: data?.data?.value,
    key: data?.data?.key,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
