import useSWR from 'swr';
import { FetchResult, UserRates } from '../entities';

type Result = FetchResult & { rates: UserRates[] };

export function useEmployeeRates(id: number | null = null): Result {
  const { data, error, mutate } = useSWR(`/user-rates/${id || ''}`);

  return {
    rates: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}