import { Button } from 'antd';
import { ReactComponent as IconChevronRight } from '../../images/icons/icon-chevron-right.svg';

type Props = {
  onGetPrevious: () => void;
  onGetNext: () => void;
};

const TimesheetTabsControls = ({ onGetPrevious, onGetNext }: Props) => {
  return (
    <>
      <Button icon={<IconChevronRight style={{ transform: 'rotate(180deg)' }} />} onClick={onGetPrevious} />
      <Button icon={<IconChevronRight />} onClick={onGetNext} />
    </>
  );
};

export default TimesheetTabsControls;
