import { message, Select } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useProjectPhases } from '../../dal/useProjectPhases';
import { useProjectTasks } from '../../dal/useProjectTasks';
import { Task } from '../../entities';

const { Option } = Select;

interface Props {
  task: Task;
}

const ChangeTasksPhase = ({ task }: Props) => {
  const projectId = task?.project?.id;
  const { data } = useProjectPhases(projectId);
  const [phaseToOverride, setPhaseToOverride] = useState<string>(task.phase?.id || '');
  const { mutate } = useProjectTasks(projectId);

  const savePhase = async (v: string) => {
    setPhaseToOverride(v);
    await axios.put(`/tasks/${task.id}`, { phase: { id: v }, id: undefined });
    message.success('Phase changed');
    await mutate();
  };

  if (data && data.length > 0) {
    return (
      <div>
        Select new phase
        <br />
        <Select style={{ width: 200 }} defaultValue={phaseToOverride} onChange={v => savePhase(v)}>
          {data.map(t => (
            <Option value={t.id} key={t.name}>
              {t.name}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  return <div>No phases available</div>;
};

export default ChangeTasksPhase;
