import useSWR from 'swr';
import { FetchResult, PhaseHistory } from '../entities';

type Result = FetchResult & { data: PhaseHistory[] };

export function usePhaseHistory(id: string): Result {
  const { data, error, mutate } = useSWR(`/tasks-phases/${id}/history`);

  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
