import { Button, Result } from 'antd';

const ErrorMessage = () => (
  <Result
    status="warning"
    title="Error occured"
    extra={
      <Button type="primary" onClick={() => (window.location.href = '/')}>
        Go to dashboard
      </Button>
    }
  />
);

export default ErrorMessage;
