import Layout from '../../layouts/authorised/Authorised';
import './index.css';
import { UserWithPermissions, useSession } from '../../dal';
import ChatApp from '../../chat/chat_main';

const Home = () => {
  const { session } = useSession();

  const getChatData = (data?: UserWithPermissions): string => {
    return `#chat-id=${process.env.REACT_APP_CHAT_ID}&token=${data?.token}&name=${data?.name}&picture=${
      data?.picture
    }&chatView=direct-chats&allow-comment`;
  };
  return <Layout isFullPage fullHeight>
    <ChatApp data={getChatData(session)} />
  </Layout>;
};

export default Home;
