import { Card, Form, Button, DatePicker } from 'antd';
import { datePickerFormat } from '../../constants';
import { ProjectPermissions } from '../../common/usePermissions/permissions';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import ProjectDate from '../../components/project/ProjectDate';
import { Project } from '../../entities';

interface Props {
  initialFormValues: any;
  project: Project;
  submitProject: (values: any) => void;
}

const ProjectDates = ({ initialFormValues, submitProject, project }: Props) => {
  const { hasPermission } = usePermissions();

  return (
    <Form name="basic" initialValues={initialFormValues} onFinish={submitProject} layout="vertical">
      <Card
        title="Project dates"
        size="small"
        extra={
          hasPermission(ProjectPermissions.PROJECT_UPDATE) && (
            <Button type="default" htmlType="submit" block size="small">
              Save
            </Button>
          )
        }
      >
        {hasPermission(ProjectPermissions.PROJECT_UPDATE) ? (
          <Form.Item label="Contract Signed Date" name="contractEndDate">
            <DatePicker format={datePickerFormat} style={{ width: '100%' }} />
          </Form.Item>
        ) : (
          <ProjectDate date={project.contractEndDate} label="Contract Signed Date" />
        )}

        {hasPermission(ProjectPermissions.PROJECT_UPDATE) ? (
          <Form.Item label="Kickoff Start Date" name="startDate">
            <DatePicker format={datePickerFormat} style={{ width: '100%' }} />
          </Form.Item>
        ) : (
          <ProjectDate date={project.startDate} label="Kickoff Start Date" />
        )}

        {hasPermission(ProjectPermissions.PROJECT_UPDATE) ? (
          <Form.Item label="Estimated End Date" name="estimatedEndDate">
            <DatePicker format={datePickerFormat} style={{ width: '100%' }} />
          </Form.Item>
        ) : (
          <ProjectDate date={project.estimatedEndDate} label="Estimated End Date" />
        )}

        {hasPermission(ProjectPermissions.PROJECT_UPDATE) ? (
          <Form.Item label="Actual End Date" name="endDate">
            <DatePicker format={datePickerFormat} style={{ width: '100%' }} />
          </Form.Item>
        ) : (
          <ProjectDate date={project.endDate} label="Actual End Date" />
        )}

        {hasPermission(ProjectPermissions.PROJECT_UPDATE) ? (
          <Form.Item label="Warranty End Date" name="warrantyEndDate">
            <DatePicker format={datePickerFormat} style={{ width: '100%' }} />
          </Form.Item>
        ) : (
          <ProjectDate date={project.warrantyEndDate} label="Warranty End Date" />
        )}
      </Card>
    </Form>
  );
};

export default ProjectDates;
