import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, message, Select, Space, Tooltip } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useProjectTasks } from '../../dal/useProjectTasks';
import { useSettings } from '../../dal/useSettings';
import { PhaseTasksSetting } from '../../pages/settingsPhases';
import PhaseConfirmation from './PhaseConfirmation';
import SaveProjectAsTemplate from './SaveProjectAsTemplate';

const { Option } = Select;

type Props = { projectId: string };

const AddTasksPhase = ({ projectId }: Props) => {
  const { phases } = useProjectTasks(projectId);
  const { settings } = useSettings<PhaseTasksSetting>('phases_tasks');
  const [name, setName] = useState('');
  const [template, setTemplate] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [saveProjectModalOpen, setSaveProjectModalOpen] = useState(false);
  const { mutate } = useProjectTasks(projectId);
  const [addingPhase, setAddingPhase] = useState(false);

  const submit = async () => {
    await axios.post(`/tasks-phases/${projectId}/project`, { name });
    setName('');
    message.success('Phase added');
    await mutate(null, true);
  };

  const showModal = async () => {
    if (template) {
      setModalOpen(true);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    setTemplate('');
  };

  return (
    <Space direction="vertical" style={{ width: '100%', marginTop: '16px' }}>
      {!addingPhase && <Button type="dashed" onClick={() => setAddingPhase(true)} block icon={<PlusOutlined />}>
        Add phase
      </Button>}

      {addingPhase && <div style={{ textAlign: 'center' }}>
        <Input.Group compact>
          <Input value={name} style={{ width: '200px' }} onChange={e => setName(e.target.value)} />
          <Button type="primary" onClick={submit}>
            Add phase
          </Button>
          <Button type="link" onClick={() => setAddingPhase(false)}>
            Cancel
          </Button>
        </Input.Group>
      </div>}

      <div style={{ textAlign: 'right' }}>
        <Button type="link" onClick={() => setSaveProjectModalOpen(true)}>
          Save project as template
        </Button>
      </div>

      {settings && settings.templates && (
        <Input.Group compact>
          <Select showSearch value={template} style={{ width: '200px' }} onChange={value => setTemplate(value)}>
            {settings.templates.map(t => (
              <Option value={t.name} key={t.name}>
                {t.name}
              </Option>
            ))}
          </Select>
          <Button type="primary" onClick={showModal}>
            Apply
          </Button>
          <Tooltip
            placement="top"
            title="You can select template and app will automatically add all phases and tasks to this project. THIS WILL REMOVE ALL EXISTING TASKS AND PHASES"
          >
            <InfoCircleOutlined style={{ fontSize: '20px', margin: '6px 0 0 6px' }} />
          </Tooltip>
        </Input.Group>
      )}
      {template && modalOpen && (
        <PhaseConfirmation projectId={projectId} template={template} onClose={handleClose} showOverrideConfirmation={!!(phases.length > 0)} />
      )}
      {saveProjectModalOpen && <SaveProjectAsTemplate projectId={projectId} onClose={() => setSaveProjectModalOpen(false)} />}
    </Space>
  );
};

export default AddTasksPhase;
