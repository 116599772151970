import { Dropdown, Menu, Tag } from 'antd';
import { TaskState } from '../../entities';

type MenuClick = {
  key: string;
};

interface Props {
  onChange: (state: TaskState) => void;
  defaultColor?: string;
  defaultValue?: string;
}

const TaskStatePicker = ({ onChange, defaultColor = 'grey', defaultValue = 'New' }: Props) => {
  const handleMenuClick = (e: MenuClick) => {
    onChange(e.key as TaskState);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          key: 'new',
          label: 'New',
        },
        {
          key: 'in progress',
          label: 'In progress',
        },
        {
          key: 'stuck',
          label: 'Stuck',
        },
        {
          key: 'finished',
          label: 'Finished',
        },
      ]}
    />
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Tag color={defaultColor} className="cursor-pointer">
        {defaultValue}
      </Tag>
    </Dropdown>
  );
};

export default TaskStatePicker;
