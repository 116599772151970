import { message, Space, Switch } from 'antd';
import { useSession } from '../../dal';
import axios from 'axios';

const NotificationsSettings = () => {
  const { session } = useSession();

  const handleNotificationsSettingsChange = async (payload: Record<string, boolean>) => {
    await axios.patch(`/users/patch-profile`, { ...payload, id: session?.id });
    message.success('Settings updated');
  };

  return (
    <Space>
      <Switch
        checkedChildren="Email"
        unCheckedChildren="Email"
        defaultChecked={session?.emailNotifications}
        onChange={(checked: boolean) => handleNotificationsSettingsChange({ emailNotifications: checked })}
      />
      <Switch
        checkedChildren="Slack"
        unCheckedChildren="Slack"
        defaultChecked={session?.slackNotifications}
        onChange={(checked: boolean) => handleNotificationsSettingsChange({ slackNotifications: checked })}
      />
    </Space>
  );
};

export default NotificationsSettings;
