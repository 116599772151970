import useSWR from 'swr';
import { FetchResult, TimesheetEntry } from '../entities';

type Result = FetchResult & { timesheet: TimesheetEntry };

export function useTimesheet(id: string | null): Result {
  const { data, error, mutate } = useSWR(id ? `/timesheets/${id}` : null);

  return {
    timesheet: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
