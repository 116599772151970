import { Button, Card } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EditOutlined } from '@ant-design/icons';
import { ProjectPermissions } from '../../common/usePermissions/permissions';
import { usePermissions } from '../../common/usePermissions/usePermissions';

type Props = {
  description: string;
  editBrief: (description: string) => void;
};

const ProjectBrief = ({ description, editBrief }: Props) => {
  const { hasPermission } = usePermissions();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>(description || '');

  const updateBrief = () => {
    editBrief(value);
    setModalOpen(false);
  };

  return (
    <>
      <Card
        title="Project Brief"
        size="small"
        extra={
          hasPermission(ProjectPermissions.PROJECT_UPDATE) && (
            <Button icon={<EditOutlined />} size="small" onClick={() => setModalOpen(true)}>
              Edit
            </Button>
          )
        }
      >
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      </Card>
      <Modal
        title="Edit brief"
        open={modalOpen}
        onOk={() => updateBrief()}
        onCancel={() => setModalOpen(false)}
        width={1000}
        bodyStyle={{ minHeight: '300px' }}
      >
        <ReactQuill theme="snow" value={value} onChange={setValue} style={{ height: '200px' }} />
      </Modal>
    </>
  );
};

export default ProjectBrief;
