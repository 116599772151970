import useSWR from 'swr';
import { buildTimesheetQueryParams } from '../common/utils';
import { ApprovalResponse, FetchResult } from '../entities';

type Result = FetchResult & ApprovalResponse;

export function useApprovals(startDate?: string, monthlyView?: boolean): Result {
  const queryString = buildTimesheetQueryParams(startDate, monthlyView);
  const { data, error, mutate } = useSWR(`/timesheets/approvals?${queryString}`);

  return {
    approvals: data?.data,
    pagination: data?.pagination,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
