import { Spin, Timeline } from 'antd';
import moment from 'moment';
import { usePhaseHistory } from '../../dal/usePhaseHistory';
import { PhaseHistory as PhaseHistoryType } from '../../entities';
import './TaskHistory.css';

function getPhaseHistoryDescription(entry: PhaseHistoryType): string {
  if (entry.changeType === 'created') {
    return `Phase created by ${entry.changedBy.name}.`;
  }
  if (entry.changeType === 'startDate') {
    return `Start date changed to ${moment(entry.newValue).format('ll')} by ${entry.changedBy.name}.`;
  }
  if (entry.changeType === 'endDate') {
    return `End date changed to ${moment(entry.newValue).format('ll')} by ${entry.changedBy.name}.`;
  }
  if (entry.changeType === 'name') {
    return `Name changed to "${entry.newValue}" by ${entry.changedBy.name}.`;
  }
  if (entry.changeType === 'isFinished') {
    return entry.newValue === 'true' ? `Marked finished by ${entry.changedBy.name}.` : `Marked unfinished by ${entry.changedBy.name}.`;
  }

  return 'Unknown action.';
}
interface Props {
  id: string;
}

const PhaseHistory = ({ id }: Props) => {
  const { data } = usePhaseHistory(id);

  if (!data) return <Spin />;

  return (
    <Timeline mode="left">
      {data.map(e => (
        <Timeline.Item label={moment(e.createdAt).format('lll')} key={e.id}>
          {getPhaseHistoryDescription(e)}
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export default PhaseHistory;
