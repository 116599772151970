import { useCallback, useState } from 'react';
import axios from 'axios';
import { Table, PageHeader, Avatar, Tag, Space, Modal, Button, DatePicker, Input, Select, Popconfirm, message, Typography } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { useEmployeeRates } from "../../dal/useEmployeeRates";
import { formatCurrency } from "../../common/utils";
import MoneyInput from '../../components/shared/MoneyInput';

const EmployeeRates = () => {
    const { rates, isLoading } = useEmployeeRates();
    const [selectedUserId, setSelectedUserId] = useState<number>(0);
    const { rates: userRates, mutate: mutateUserRates } = useEmployeeRates(selectedUserId);
    const [newRateData, setNewRateData] = useState<any>({})
    const [search, setSearch] = useState<string | null>();

    const modalCancel = useCallback(() => {
        setSelectedUserId(0)
    }, []);

    const onRateAdd = useCallback(async () => {
        await axios.post(`/user-rates/${selectedUserId}`, {
            ...newRateData
        });
        mutateUserRates();
        setNewRateData({})
    }, [selectedUserId, newRateData, mutateUserRates]);

    const onRateDelete = useCallback(async (id) => {
        const { data } = await axios.delete(`/user-rates/${id}`);
        if (data.error) {
            message.error(data.error);
            return;
        }
        mutateUserRates();
    }, [mutateUserRates])

    const summary = () => (
        <>
            <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={2} align="center">
                    <DatePicker picker="month" onChange={(_, dateString) => setNewRateData({ ...newRateData, validFrom: dateString + '-01' })} />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="right">
                    <MoneyInput min={0} onChange={rateValue => setNewRateData({ ...newRateData, rateValue })} />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                    <Select style={{ width: '140px' }} onChange={rateType => setNewRateData({ ...newRateData, rateType })}>
                        <Select.Option value="hourly">Hourly</Select.Option>
                        <Select.Option value="monthly">Monthly</Select.Option>
                    </Select>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="right">
                    <MoneyInput min={0} onChange={averageHourlyRate => setNewRateData({ ...newRateData, averageHourlyRate })} />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                    <Input onChange={e => setNewRateData({ ...newRateData, rateComment: e.target.value })} />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                    <Button icon={<PlusOutlined />} onClick={onRateAdd}>Add</Button>
                </Table.Summary.Cell>
            </Table.Summary.Row>
        </>
    );

    const foundRates = (rates || []).filter(r => !search || (r.user && r.user.name.toLowerCase().indexOf(search.toLowerCase()) > -1));

    return  <div className='flex flex-col h-full'>
        <PageHeader title="Employee Rates" subTitle={<Input.Search onSearch={s => setSearch(s)} allowClear />} />
        <div className="ant-space-vertical">
            <Table dataSource={foundRates} pagination={false} loading={isLoading} rowClassName={(row: any) => !row.user.active ? "table-row-disabled" : ""}>
                <Table.Column dataIndex={['user', 'picture']} render={url => <Avatar src={url} />} width="32px" />
                <Table.Column dataIndex={['user', 'name']} title="Employee name" render={(name, row: any) => row.user.active ? name : `${name} (inactive)`} />
                <Table.Column dataIndex={['user', 'positions']} title="Positions" render={(values, row: any) => <Space direction="vertical"><span>{row.user.title}</span>{values.map(v => <Tag>{v}</Tag>)}</Space>} />
                <Table.Column dataIndex={'rateValue'} title="Rate value" render={formatCurrency} align="right" />
                <Table.Column dataIndex={'rateType'} title="Rate type" />
                <Table.Column dataIndex={'averageHourlyRate'} title={<><div>Avg. hourly rate</div><Typography.Text type="secondary">(for estimates)</Typography.Text></>} render={formatCurrency} align="right" />
                <Table.Column dataIndex={'rateComment'} title="Comment" />
                <Table.Column dataIndex={['user', 'id']} render={id => <Button type="link" onClick={() => setSelectedUserId(id)}>History</Button>} />
            </Table>
        </div>
        <Modal title="User rates" open={!!selectedUserId} onCancel={modalCancel} width="1200px">
            <Table dataSource={userRates} pagination={false} summary={summary}>
                <Table.Column dataIndex={'validFrom'} title="Valid from" width="120px" />
                <Table.Column dataIndex={'validTo'} title="Valid to" width="120px" />
                <Table.Column dataIndex={'rateValue'} title="Rate value" render={formatCurrency} align="right" width="120px" />
                <Table.Column dataIndex={'rateType'} title="Rate type" width="150px" />
                <Table.Column dataIndex={'averageHourlyRate'} title="Avg. hourly rate" render={formatCurrency} align="right" width="150px" />
                <Table.Column dataIndex={'rateComment'} title="Comment" />
                <Table.Column dataIndex={'id'} title="Actions" render={id => <Popconfirm title="Are you sure?" onConfirm={() => onRateDelete(id)}><Button type="link">Delete</Button></Popconfirm>} width="80px" />
            </Table>
        </Modal>
    </div >
};

export default EmployeeRates;