import { Select, Avatar, SelectProps } from 'antd';
import { useUsers } from '../../dal';

const UserSelector = (props: SelectProps) => {
  const { users = [] } = useUsers();

  return (
    <Select
      {...props}
      showSearch
      placeholder="Select user"
      getPopupContainer={trigger => trigger.parentElement}
      filterOption={(input, option) => {
        return `${option?.children?.[1]}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {users.map(u => (
        <Select.Option key={u.id} value={u.id}>
          <Avatar src={u.picture} style={{ marginRight: '6px' }} size="small" />
          {u.name || u.email}
        </Select.Option>
      ))}
    </Select>
  );
};

export default UserSelector;
