import { useState } from 'react';
import { Button, Divider, message, Input } from 'antd';
import axios from 'axios';
import { User } from '../../entities';

interface Props {
  user: User;
}

const UserSlackId = ({ user }: Props) => {
  const [id, setId] = useState<string | undefined>(user.slackUserId || undefined);

  const submit = async () => {
    await axios.patch(`/users/${user.id}`, { slackUserId: id });
    message.success('Saved');
  };

  return (
    <>
      <Divider orientation="left">Slack ID</Divider>
      <Input.Group compact>
        <Input value={id} onChange={e => setId(e.target.value)} style={{ width: '200px' }} />
        <Button type="primary" onClick={submit}>
          Submit
        </Button>
      </Input.Group>
    </>
  );
};

export default UserSlackId;
