import useSWR from 'swr';
import { FetchResult, Task } from '../entities';

type Result = FetchResult & { myTasks: Task[]; projectsTasks: Task[] };

export function useTasks(): Result {
  const { data, error, mutate } = useSWR(`/tasks`);

  return {
    myTasks: data?.data.myTasks,
    projectsTasks: data?.data.projectsTasks,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
