import useSWR from 'swr';
import { FetchResult, TimesheetSelfResponse } from '../entities';
import { buildTimesheetQueryParams } from '../common/utils';

type Result = FetchResult & TimesheetSelfResponse;

export function useSelfTimesheetForProject(id?: string, startDate?: string): Result {
  const queryString = buildTimesheetQueryParams(startDate);
  const { data, error, mutate } = useSWR(id ? `/timesheets/${id}/user/project?${queryString}` : null);

  return {
    timesheets: data?.data,
    user: data?.user,
    pagination: data?.pagination,
    weekSubmition: data?.weekSubmition,
    monthSubmition: data?.monthSubmition,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
