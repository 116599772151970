import { Select, SelectProps } from 'antd';
import { useMembersRoles } from '../../dal';

const UserPositionsSelector = (props: SelectProps) => {
  const { roles } = useMembersRoles();

  return (
    <>
      <Select {...props} mode="multiple" allowClear placeholder="Please select roles">
        {roles.map(r => (
          <Select.Option key={r}>{r}</Select.Option>
        ))}
      </Select>
    </>
  );
};

export default UserPositionsSelector;
