import { Button, Collapse, DatePicker, Form, Input, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import axios from 'axios';
import ReactQuill from 'react-quill';
import { useSettings } from '../../dal/useSettings';
import { HomepageSetting, HomepageSettings } from '../../entities';
import moment from 'moment';
import { useEffect } from 'react';
import { sortHomepageSettingsByDate } from '../../common/utils';

const HomepageEditor = () => {
  const [form] = useForm();
  const { settings, key, mutate } = useSettings<HomepageSettings>('homepage_enhanced');
  const formData = settings && sortHomepageSettingsByDate(settings).map(s => ({ ...s, dateFrom: moment(s.dateFrom) }));

  const onSubmit = async () => {
    const formattedSettings = form.getFieldsValue().settings.map((s: HomepageSetting) => ({ ...s, dateFrom: new Date(moment(s.dateFrom).toDate()) }));
    await axios.post(`/settings`, { key: key, value: formattedSettings });
    await mutate();
    message.success('Saved successfully');
  };

  useEffect(() => {
    form.setFieldsValue({ settings: formData });
  }, [formData, form]);

  return (
    <Form form={form} onFinish={onSubmit} autoComplete="off" initialValues={{ settings: formData }} layout="vertical">
      <Form.List name="settings">
        {(fields, { add, remove }) => {
          return (
            <>
              <Collapse bordered={false} defaultActiveKey={['1']}>
                {fields.map((field: any) => {
                  const fieldDate = form.getFieldValue(['settings', field.name, 'dateFrom']);
                  const header = fieldDate ? `From: ${moment(fieldDate).format('ll')}` : `New entry`;
                  return (
                    <Collapse.Panel header={header} key={field.key}>
                      <Form.Item
                        fieldKey={[field.fieldKey, 'url']}
                        label="Image URL"
                        name={[field.name, 'url']}
                        rules={[{ required: true, message: 'Url is required' }]}
                      >
                        <Input placeholder="Image URL" style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item
                        fieldKey={[field.fieldKey, 'dateFrom']}
                        label="Start date"
                        name={[field.name, 'dateFrom']}
                        rules={[{ required: true, message: 'Date is required' }]}
                      >
                        <DatePicker placeholder="Start date" style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item
                        fieldKey={[field.fieldKey, 'credits']}
                        label="Credits"
                        name={[field.name, 'credits']}
                        rules={[{ required: true, message: 'Credits are required' }]}
                      >
                        <ReactQuill style={{ height: '100px', marginBottom: 50 }} />
                      </Form.Item>
                      <Form.Item
                        fieldKey={[field.fieldKey, 'welcomeMessage']}
                        label="Welcome message"
                        name={[field.name, 'welcomeMessage']}
                        rules={[{ required: true, message: 'Message is required' }]}
                      >
                        <ReactQuill style={{ height: '200px' }} />
                      </Form.Item>
                      <Button danger onClick={() => remove(field.name)} block icon={<MinusOutlined />} style={{ marginTop: 32 }}>
                        Delete
                      </Button>
                    </Collapse.Panel>
                  );
                })}
              </Collapse>

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ marginTop: 4 }}>
                  Add setting
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>

      <Button type="primary" htmlType="submit">
        Save
      </Button>
    </Form>
  );
};

export default HomepageEditor;
