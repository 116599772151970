import { Divider, message, Select } from 'antd';
import axios from 'axios';
import { User } from '../../entities';

interface Props {
  user: User;
}

const UserRoles = ({ user }: Props) => {
  const roles = ['admin', 'finances', 'manager', 'client', 'user', 'sales'];

  const handleChange = async (roles: string[]) => {
    await axios.patch(`/users/${user.id}`, { roles });
    message.success('Saved');
  };

  return (
    <>
      <Divider orientation="left">Application access settings</Divider>
      <Select
        mode="multiple"
        allowClear
        style={{ width: '400px' }}
        placeholder="Please select roles"
        defaultValue={user.roles}
        onChange={handleChange}
      >
        {roles.map(r => (
          <Select.Option key={r}>{r}</Select.Option>
        ))}
      </Select>
    </>
  );
};

export default UserRoles;
