import useSWR from 'swr';
import { FetchResult, Project } from '../entities';

type Result = FetchResult & { projects: Project[] };

export function useProjects(): Result {
  const { data, error, mutate } = useSWR(`/projects`);

  return {
    projects: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
