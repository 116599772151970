import { Col, Statistic, Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { displayHoursFromNumber, getTotalHours } from '../../../common/utils';
import { Timesheet } from '../../../entities';

function getTotalHoursForCategory(category: string, timesheets?: Timesheet): number {
  return !timesheets ? 0 : timesheets.reduce((prev, curr) => (curr.category === category ? prev + curr.hours : prev), 0);
}

type Props = {
  timesheets: Timesheet;
  categories: string[];
  roles: string[];
};

const ReportStats = ({ timesheets, categories, roles }: Props) => {
  return (
    <>
      <Col span={24}>
        <Statistic title="Total hours" value={displayHoursFromNumber(getTotalHours(timesheets))} prefix={<ClockCircleOutlined />} />
      </Col>
      {categories.map(c => (
        <Col span={8} key={c}>
          <Statistic 
            title={<>{c} {roles.indexOf(c) === -1 && <Typography.Text type="danger">legacy</Typography.Text>}</>} 
            value={displayHoursFromNumber(getTotalHoursForCategory(c, timesheets))} />
        </Col>
      ))}
    </>
  );
};

export default ReportStats;
