import { CalendarOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import axios from 'axios';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { TasksPermissions } from '../../common/usePermissions/permissions';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { datePickerFormat } from '../../constants';
import { useProjectTasks } from '../../dal/useProjectTasks';
import { TaskPhase } from '../../entities';

function getDiff(startDate: Date, endDate: Date) {
  const start = moment(startDate);
  const end = moment(endDate);
  const days = end.diff(start, 'days');
  return days === 0 ? null : days;
}

const PhaseHeaderDates = ({ phase }: { phase: TaskPhase }) => {
  const { mutate } = useProjectTasks(phase.project.id);
  const { hasPermission } = usePermissions();
  const canEdit = hasPermission(TasksPermissions.TASKS_PHASES_WRITE);
  const [editMode, setEditMode] = useState<boolean>(false);
  const startDateVisual = phase.startDate ? moment(phase.startDate).format(datePickerFormat) : 'not set';
  const endDateVisual = phase.endDate ? moment(phase.endDate).format(datePickerFormat) : 'not set';
  const lengthVisual = phase.startDate && phase.endDate ? getDiff(phase.startDate, phase.endDate) : null;
  const [startDate, setStartDate] = useState<Moment | null>(phase.startDate ? moment(phase.startDate) : null);
  const [endDate, setEndDate] = useState<Moment | null>(phase.endDate ? moment(phase.endDate) : null);

  const changeStartDate = async (date: Moment | null) => {
    setStartDate(date);
    await axios.patch(`/tasks-phases/${phase.id}`, { startDate: new Date(moment(date).format()) });
    await mutate();
  };

  const changeEndDate = async (date: Moment | null) => {
    setEndDate(date);
    await axios.patch(`/tasks-phases/${phase.id}`, { endDate: new Date(moment(date).format()) });
    await mutate();
  };

  return (
    <>
      {canEdit && editMode ? (
        <div className="phase-dates-container">
          <span className={`phase-header-value ${!hasPermission(TasksPermissions.TASKS_PHASES_WRITE) && 'phase-header-value--non-interactive'}`}>
            <DatePicker placeholder="Start date" value={startDate} onChange={date => changeStartDate(date)} size="small" />
          </span>
          <span className={`phase-header-value ${!hasPermission(TasksPermissions.TASKS_PHASES_WRITE) && 'phase-header-value--non-interactive'}`}>
            <DatePicker placeholder="End date" value={endDate} onChange={date => changeEndDate(date)} size="small" />
          </span>
          <CloseCircleOutlined className="phase-header-icon" onClick={() => setEditMode(false)} />
        </div>
      ) : (
        <div className="phase-dates-container">
          <span
            className={`phase-header-value ${!hasPermission(TasksPermissions.TASKS_PHASES_WRITE) && 'phase-header-value--non-interactive'}`}
            onClick={canEdit ? () => setEditMode(true) : undefined}
          >
            <CalendarOutlined />
            &nbsp;Start:&nbsp;<strong>{startDateVisual}</strong>
          </span>
          <span
            className={`phase-header-value ${!hasPermission(TasksPermissions.TASKS_PHASES_WRITE) && 'phase-header-value--non-interactive'}`}
            onClick={canEdit ? () => setEditMode(true) : undefined}
          >
            <CalendarOutlined />
            &nbsp;End:&nbsp;<strong>{endDateVisual}</strong>
          </span>
          {canEdit && <EditOutlined className="phase-header-icon" onClick={() => setEditMode(true)} />}
        </div>
      )}
      {lengthVisual && lengthVisual > 0 && (
        <span className="phase-header-value" style={{ cursor: 'auto' }}>
          <CalendarOutlined />
          &nbsp;Length:&nbsp;<strong>{lengthVisual} days</strong>
        </span>
      )}
    </>
  );
};

export default PhaseHeaderDates;
