import { useEffect } from 'react';

interface Props {
  active: boolean;
  chatView: string;
  channelName?: string;
  allowComment: boolean;
  height: string | number;
  className?: string;
}

const ChatWindow = ({ active, chatView, channelName, allowComment, height, className }: Props) => {
  useEffect(() => {
    if (active) {
      window.chat?.setChannel({
        el: 'ddc-chat-' + chatView,
        chatView,
        channelName,
        allowComment,
      });
    } else if (window.chat?.getChannel() === chatView) {
      window.chat.close();
    }
  }, [active, chatView, channelName, allowComment]);

  useEffect(() => {
    return () => {
      window.chat.close();
    };
  }, []);

  return <div className={className} id={'ddc-chat-' + chatView} style={{ height }}></div>;
};

export default ChatWindow;
