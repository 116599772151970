import useSWR from 'swr';
import { FetchResult, Presentation } from '../entities';

type Result = FetchResult & { presentations: Presentation[] };

export function usePresentations(): Result {
  const { data, error, mutate } = useSWR(`/presentations`);

  return {
    presentations: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
