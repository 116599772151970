import axios from 'axios';
import { Task, TaskPhase } from '../../entities';
import TasksTableContainer from './TasksTableContainer';
import { useProjectTasks } from '../../dal/useProjectTasks';

type Props = { phase: TaskPhase };

const TasksPhase = ({ phase }: Props) => {
  const { mutate: mutateProjectTasks } = useProjectTasks(phase.project.id);

  const handleTaskChange = async (values: Partial<Task>) => {
    const resp = await axios.put(`/tasks/${values.id}`, { ...values, phase: phase.id, id: undefined }, { params: { projectId: phase.project.id } });
    await mutateProjectTasks(resp.data);
  };

  if (!phase.tasks) return null;

  return (
    <TasksTableContainer
      data={phase.tasks}
      initialProjectId={phase.project.id}
      initialPhaseId={phase.id}
      phase={phase}
      personal={false}
      onTaskChange={handleTaskChange}
    />
  );
};

export default TasksPhase;
