import useSWR from 'swr';
import { FetchResult } from '../entities';

type Result = FetchResult & { files: string[] };

export function useFiles(): Result {
  const { data, error, mutate } = useSWR('/files');

  return {
    files: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
