import { useState } from 'react';
import { Task } from '../../entities';
import ProjectSelector from '../project/ProjectSelector';

interface Props {
  task: Task;
  onTaskChange: (data: Partial<Task>) => Promise<void>;
}

const TaskProject = ({ task, onTaskChange }: Props) => {
  const [id, setId] = useState<string | undefined>(task.project?.id || undefined);

  const handleChange = (value: string) => {
    setId(value);
    onTaskChange({ id: task.id, project: value as any });
  };

  return <ProjectSelector value={id} onChange={handleChange} style={{ width: '100%' }} />;
};

export default TaskProject;
