import { Tag, Select } from 'antd';
import { BaseSelectRef, CustomTagProps } from 'rc-select/lib/BaseSelect';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as CloseIconModal } from '../../../static/images/close_icon_modal.svg';

interface Props {
  options: { label: JSX.Element; value: string }[] | any[];
  onChange: (data: any) => void;
}

const tagRender = ({ label, value, closable, onClose }: CustomTagProps) => {
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
      closeIcon={<CloseIconModal className="ml-[6px] mr-[4px]" />}
    >
      {label}
    </Tag>
  );
};

const UserFinder: React.FunctionComponent<Props> = ({ options, onChange }) => {
  const [selected, setSelected] = React.useState<string[]>();
  const SelectRef = React.createRef<BaseSelectRef>();
  const location = useLocation();

  React.useEffect(() => {
    setSelected([]);
  }, [location]);

  React.useEffect(() => {
    if (SelectRef.current) {
      SelectRef.current.focus();
    }
  }, [SelectRef]);

  return (
    <Select
      ref={SelectRef}
      onChange={(value: string[]) => {
        setSelected(value);
        onChange(value);
      }}
      placeholder="Type user name"
      value={selected}
      mode="multiple"
      showAction={['focus']}
      tagRender={tagRender}
      showArrow
      style={{ minWidth: '250px' }}
      options={options.sort((a, b) => {
        if (a.value.toLocaleLowerCase() < b.value.toLocaleLowerCase()) {
          return -1;
        }
        if (a.value.toLocaleLowerCase() > b.value.toLocaleLowerCase()) {
          return 1;
        }
        return 0;
      })}
    />
  );
};

export default UserFinder;
