import { Descriptions } from 'antd';
import { Fragment } from 'react';
import { User } from '../../entities';

const UserOverview = ({ user }: { user: User }) => {
  return (
    <Descriptions title="User Info">
      {user.title && <Descriptions.Item label="Title">{user.title}</Descriptions.Item>}
      {user.role && <Descriptions.Item label="Role">{user.role}</Descriptions.Item>}
      {user.monthOfBirth && user.dayOfBirth && (
        <Descriptions.Item label="Birthday">
          {user.monthOfBirth} {user.dayOfBirth}
        </Descriptions.Item>
      )}
      {user.bio && (
        <Descriptions.Item label="Bio" span={3}>
          {user.bio}
        </Descriptions.Item>
      )}
      {user.links && user.links.length > 0 && (
        <Descriptions.Item label="Links" span={3}>
          {user.links.map((l, i) => (
            <Fragment key={i}>
              <a href={l.link} target="_blank" rel="noreferrer">
                {l.label}
              </a>
              {i !== user.links.length - 1 && <>,&nbsp;</>}
            </Fragment>
          ))}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

export default UserOverview;
