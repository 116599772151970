import { Ticket } from '../../entities';
import { Switch } from 'antd';

interface Props {
  ticket: Ticket;
  onChange: (id: string, values: Partial<Ticket>) => void;
}

const TicketFinanciallyClosed = ({ ticket, onChange }: Props) => {
  return <Switch defaultChecked={ticket.financiallyClosed} onChange={(checked: boolean) => onChange(ticket.id, { financiallyClosed: checked })} />;
};

export default TicketFinanciallyClosed;
