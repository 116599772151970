import { Col, Statistic } from 'antd';
import { displayHoursFromNumber } from '../../../common/utils';
import { Timesheet, User } from '../../../entities';

function getTotalHoursForUserAndCategories(userId: number, categories: string[], timesheets?: Timesheet): number {
  return !timesheets
    ? 0
    : timesheets.reduce((prev, curr) => (curr.user.id === userId && categories.includes(curr.category) ? prev + curr.hours : prev), 0);
}

function getMembersFromTimesheets(timesheets: Timesheet): User[] {
  const members: User[] = [];
  timesheets?.forEach(t => {
    const checkIfAlreadyAdded = members.filter(m => m.id === t.user.id);
    if (checkIfAlreadyAdded.length === 0) {
      members.push(t.user);
    }
  });

  return members;
}

type Props = {
  timesheets: Timesheet;
  checkedList: string[];
};

const ReportUserStats = ({ timesheets, checkedList }: Props) => {
  const members = getMembersFromTimesheets(timesheets);

  return (
    <>
      {members.map(m => (
        <Col span={8} key={m.id}>
          <Statistic title={m.name} value={displayHoursFromNumber(getTotalHoursForUserAndCategories(m.id, checkedList, timesheets))} />
        </Col>
      ))}
    </>
  );
};

export default ReportUserStats;
