import { Tag } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ProjectFinance } from '../../entities';

export const OutstandingAmount = ({ amount }: { amount: number }) =>
  amount === 0 ? (
    <Tag icon={<CheckCircleOutlined />} color="success">
      Paid
    </Tag>
  ) : (
    <>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)}</>
  );

export const getOutstandingAmount = (finance: ProjectFinance): number => {
  return (
    finance.budget -
    finance.payments.reduce((prev, curr) => {
      return curr?.paid ? prev + curr.value : prev;
    }, 0)
  );
};
