import useSWR from 'swr';
import { FetchResult, Notification } from '../entities';

type Result = FetchResult & { notifications: Array<Notification> };

export function useNotifications(): Result {
  const { data, error, mutate } = useSWR(`/notifications`);

  return {
    notifications: data?.data.notifications,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
