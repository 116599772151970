import { Switch } from 'antd';

export const FinancialInfoSwitch = ({ isChecked, onChange }: { isChecked: boolean; onChange: (v: boolean) => void }) => (
  <Switch
    checkedChildren="Financial info"
    unCheckedChildren="Financial info"
    checked={isChecked}
    onChange={checked => onChange(checked)}
    style={{ marginLeft: '20px', marginRight: '20px' }}
  ></Switch>
);
