import { useState } from 'react';

export function useTimesheetModal(): {
  entryId: string | null;
  modalOpen: boolean;
  openModal: (id?: string) => void;
  setModalOpen: (isOpen: boolean) => void;
  setEntryId: (entryId: string | null) => void;
} {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [entryId, setEntryId] = useState<string | null>(null);

  const openModal = (id?: string) => {
    if (id) {
      setEntryId(id);
      setModalOpen(true);
    } else {
      setModalOpen(true);
    }
  };

  return { entryId, modalOpen, openModal, setModalOpen, setEntryId };
}
