import { useState } from 'react';
import { Switch, Spin, Select, message } from 'antd';
import { useProject, useProjects } from '../../dal';
import axios from 'axios';
import { Project, ProjectStatus } from '../../entities';

const { Option, OptGroup } = Select;

type Props = {
  project: Project;
  hasPermissionForDraft: boolean;
};

const ProjectFinish = ({ project, hasPermissionForDraft }: Props) => {
  const { mutate } = useProject(project.id);
  const { mutate: mutateProjects } = useProjects();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const switchProject = async (status: ProjectStatus) => {
    setIsLoading(true);
    const resp = await axios.patch(`/projects/${project.id}/switch`, { status });
    await mutate(resp.data, true);
    mutateProjects();
    setIsLoading(false);
    message.success(`Project status switched to ${status}`)
  };

  if (!project) return <Spin />;

  if (hasPermissionForDraft) {
    return (
      <Select defaultValue={project.status} style={{ width: 120 }} onChange={value => switchProject(value)} loading={isLoading} className="custom-project-button">
        <OptGroup label="status">
          <Option value="active">active</Option>
          <Option value="finished">finished</Option>
          <Option value="draft">draft</Option>
        </OptGroup>
      </Select>
    );
  }

  return (
    <Switch
      checkedChildren="Finished"
      unCheckedChildren="Active"
      defaultChecked={project.status === 'active' ? false : true}
      loading={isLoading}
      onChange={value => switchProject(value ? 'finished' : 'active')}
    />
  );
};

export default ProjectFinish;