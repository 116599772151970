import { CloseOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import React from 'react';
import ReactDOM from 'react-dom'
import { useHotkeys } from 'react-hotkeys-hook';
import { User } from '../../../entities';

import './ImageModal.css'

interface IProps {
  user: User;
  image: string;
  setShowImage: (state: string | null) => void
}

const ImageModal: React.FunctionComponent<IProps> = ({ user, image, setShowImage }) => {
  const [container] = React.useState(() => {
    return document.getElementById('chat-tabs')!;
  });

  const BgRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (BgRef && BgRef.current) {
      BgRef.current.style.backgroundImage = `url("${image}")`
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BgRef])


  useHotkeys('esc', (e) => {
    handleCloseImage()
  }, {enableOnContentEditable: true});


  const handleCloseImage = () => {
    setShowImage(null)
  }

  const handleDownloadImage = () => {
    fetch(image)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = image.split('/')[image.split('/').length - 1];
        link.click();
      })
      .catch(console.error);
  }

  return ReactDOM.createPortal(
    <section className="image-modal-container">
      <div className="image-modal h-full flex flex-col relative">
        {/* BG BLUR */}
        <div ref={BgRef} className="p-media_viewer_modal__blurry_background"></div>

        {/* HEADER */}
        <header className="image-modal-header z-[1]">
          <div className="im-user-info flex justify-between">
            <div className="flex">
              <div className="im-user-logo">
                <img src={user.picture} alt="" />
              </div>
              <div className="im-user-text flex flex-col">
                <div className="im-user-name font-[700] text-[17px] text-white">{user.name}</div>
                <div className="im-user-name text-[13px] text-white">{image.split('/')[image.split('/').length - 1]}</div>
              </div>
            </div>
            <div
              onClick={handleCloseImage}
              className="im-user-close hover:bg-[#1d1c1dd9] bg-[#ffffff0d] flex items-center justify-center rounded-[4px] w-[35px] h-[35px] cursor-pointer"
            >
              <CloseOutlined className=" text-white text-xl leading-[0]" />
            </div>
          </div>
        </header>

        {/* IMAGE */}
        <div className="im-container w-full m-auto flex justify-center items-center z-[1]">
          <img className={`min-w-[300px] max-h-[500px]`} src={image} alt="" />
        </div>

        {/* FOOTER */}
        <footer className="image-modal-footer z-[1]">
          <div
            onClick={handleDownloadImage}
            className="im-user-download hover:bg-[#1d1c1dd9] bg-[#ffffff0d] flex items-center justify-center rounded-[4px] w-[35px] h-[35px] cursor-pointer"
          >
            <CloudDownloadOutlined className=" text-white text-xl leading-[0]" />
          </div>
        </footer>
      </div>
    </section>,
    container,
  );
};

export default ImageModal