import { Typography } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useState, useCallback } from 'react';
import { Task } from '../../entities';
import confetti from "canvas-confetti";

interface Props {
  task: Task;
  onTaskChange: (data: Partial<Task>) => Promise<void>;
}

const TaskName = ({ task, onTaskChange }: Props) => {
  const [name, setName] = useState<string>(task.name);

  const save = useCallback(name => {
    setName(name);
    onTaskChange({ id: task.id, name });
  }, [task.id, onTaskChange]);

  const interactiveDone = async (e: any) => {
    confetti({
      particleCount: 80,
      spread: 70,
      ticks: 40,
      gravity: 2,
      startVelocity: 25,
      origin: {
        x: e.pageX / window.innerWidth,
        y: e.pageY / window.innerHeight
      }
    });
    onTaskChange({ id: task.id, status: 'done' });
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {task.status === 'todo' && (
        <CheckCircleOutlined style={{ fontSize: '22px', marginRight: '10px' }} onClick={interactiveDone} />
      )}
      {task.status === 'done' && (
        <CheckCircleOutlined
          style={{ color: 'green', fontSize: '22px', marginRight: '10px' }}
          onClick={() => onTaskChange({ id: task.id, status: 'todo' })}
        />
      )}
      <Typography.Text editable={{ onChange: save, triggerType: ["icon", "text"], autoSize: true }}>{name}</Typography.Text>
    </div>
  );
};

export default TaskName;
