export const INITIAL_AMOUNT_OF_LOADED_COMMENTS = 5;
export const AMOUNT_OF_COMMENTS_TO_LOAD = 10;

export const INITIAL_AMOUNT_OF_LOADED_MESSAGES = 30;
export const AMOUNT_OF_MESSAGES_TO_LOAD = 10;

export const CHAT_ACTION_MESSAGE = "CHAT_ACTION_MESSAGE"
export const POST_MESSAGE_NOTIFICATION = "POST_MESSAGE_NOTIFICATION"
export const ALL_CHAT_INFO = "ALL_CHAT_INFO"

export const CHANNEL_TYPES = Object.freeze({
  INDIVIDUAL: "individual",
  PRIVATE: "private",
  GLOBAL: "global"
})

export const URL_PARAMS = Object.freeze({
  VIEW: 'chatView'
})

export const VIEW_TYPES = Object.freeze({
  DIRECT: 'direct-chats'
})

export const S3_URL_MASK = `https://${process.env.REACT_APP_AWS_BUCKET}.${process.env.REACT_APP_AWS_ENDPOINT_URL}/${process.env.REACT_APP_AWS_UPLOAD_PREFIX}/`

export const MESSAGE_ACTIONS = Object.freeze({
  ADD_TO_CHANNEL: 'add-to-channel',
  NO_ACTION: 'no-action'
})

export const MESSAGE_ACTIONS_DATA: { [key: string]: { title: string } } = Object.freeze({
  [MESSAGE_ACTIONS.ADD_TO_CHANNEL]: { title: "Invite Them" },
  [MESSAGE_ACTIONS.NO_ACTION]: { title: "Do Nothing" }
})
