import { useClientTimesheets } from '../../dal/useClientTimesheet';
import { Client } from '../../entities';
import Report from '../shared/Report';

type Props = {
  client: Client;
};

const ClientReport = ({ client }: Props) => {
  const { timesheets } = useClientTimesheets(client.id);

  return <Report timesheets={timesheets} />;
};

export default ClientReport;
