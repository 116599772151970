import useSWR from 'swr'
import { FetchResult, Client } from '../entities';

type Result = FetchResult & { clients: Client[]}

export function useClients() : Result {
    const { data, error, mutate } = useSWR(`/clients`);

    return {
      clients: data?.data,
      isLoading: !error && !data,
      isError: error,
      mutate
    }
}