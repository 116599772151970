import { Project } from '../../../../entities';
import ProjectAdvanced from '../../../components/projectAdvanced';
import { DashboardPages, ISelectedPage } from '../types/types';

interface AdvancedProjectViewProps {
  projects: Project[];
  chatUnread: Record<string, any>;
  selectedPage: ISelectedPage<any>;
  onSelectPage: (type: DashboardPages, data: Project) => void;
}

const AdvancedProjectView = ({ projects, chatUnread, selectedPage, onSelectPage }: AdvancedProjectViewProps) => {
  return (
    <>
      {projects.length ? (
        projects.map(project => (
          <ProjectAdvanced
            key={project.id}
            project={project}
            chatUnread={chatUnread}
            isSelected={selectedPage.type === DashboardPages.PROJECT && selectedPage.data.id === project.id}
            className={`mx-[8px] project-item-adv project-item ${
              selectedPage.type === DashboardPages.PROJECT && selectedPage.data.id === project.id ? '!text-white selected-item' : '!text-[#818181]'
            }`}
            handleClickSelect={onSelectPage}
          />
        ))
      ) : (
        <div className="pt-[280px] text-center">no results</div>
      )}
    </>
  );
};

export default AdvancedProjectView;
