import useSWR from 'swr';
import { useCookies } from 'react-cookie';

export function useTimesheetYesterday(): [string, (value: string) => void] {
  let expiresDate = new Date();
  expiresDate.setHours(0, 0, 0);
  expiresDate.setDate(expiresDate.getDate() + 1);

  const [cookies, setCookies] = useCookies(['yesterday_submition']);
  const yesterdaySubmition = cookies.yesterday_submition;

  let value: string;

  if (yesterdaySubmition === 'true' || yesterdaySubmition === 'disabled') value = yesterdaySubmition;

  const { data } = useSWR(() => (yesterdaySubmition === 'true' || yesterdaySubmition === 'disabled' ? null : `/timesheets/yesterday`));
  value = data ? data.data.toString() : value!;

  if (data && data.data) {
    setCookies('yesterday_submition', data.data.toString(), { path: '/', expires: expiresDate });
  }

  return [value, (value: string) => setCookies('yesterday_submition', value, { path: '/', expires: expiresDate })];
}
