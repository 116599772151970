import useSWR from 'swr';
import { FetchResult, TimesheetResponse } from '../entities';

type Result = FetchResult & Pick<TimesheetResponse, 'timesheets'>;

export function useTaskTimesheet(taskId: string): Result {
  const { data, error, mutate } = useSWR(`/timesheets/${taskId}/task`);

  return {
    timesheets: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
