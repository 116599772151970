import { ArrowUpOutlined, MoreOutlined, DeleteOutlined, HistoryOutlined } from '@ant-design/icons';
import { Drawer, Dropdown, Menu, message, Typography, InputNumber } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import axios from 'axios';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { TasksPermissions } from '../../common/usePermissions/permissions';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { useProjectTasks } from '../../dal/useProjectTasks';
import { Task, TaskPhase } from '../../entities';
import Check from '../shared/Check';
import './PhaseHeader.css';
import PhaseHeaderDates from './PhaseHeaderDates';
import PhaseHistory from './PhaseHistory';

const PhaseHeader = ({ phase, tasks }: { phase: TaskPhase; tasks: Task[] }) => {
  const { mutate } = useProjectTasks(phase.project.id);
  const { hasPermission } = usePermissions();
  const [name, setName] = useState<string>(phase.name);
  const [historyVisible, sethHistoryVisible] = useState(false);

  const totalEstimated = tasks.reduce((acc, t) => acc + (t.estimate || 0), 0);

  const changePhaseFinish = async () => {
    await axios.patch(`/tasks-phases/${phase.id}`, { isFinished: !phase.isFinished });
    await mutate();
  };

  const debouncedPhaseName = useDebouncedCallback(name => {
    setName(name);
    changeName(name);
  }, 500);

  const changeName = async (name: string) => {
    await axios.patch(`/tasks-phases/${phase.id}`, { name });
    await mutate();
  };

  const changeEstimate = async (estimate: number | null) => {
    await axios.patch(`/tasks-phases/${phase.id}`, { estimate });
    await mutate();
  };

  const moveUp = async () => {
    await axios.post(`/tasks-phases/${phase.id}/move-up`);
    await mutate();
  };

  const moveDown = async () => {
    await axios.post(`/tasks-phases/${phase.id}/move-down`);
    await mutate();
  };

  const deletePhase = async (deleteTasks: boolean) => {
    await axios.delete(`/tasks-phases/${phase.id}?deleteTasks=${deleteTasks}`);
    await mutate();
    message.success('Phase deleted');
  };

  const menuItems: ItemType[] = [];

  if (hasPermission(TasksPermissions.TASKS_PHASES_WRITE)) {
    menuItems.push(
      {
        label: 'Move up',
        key: '1',
        icon: <ArrowUpOutlined />,
        onClick: moveUp,
      },
      {
        label: 'Move down',
        key: '2',
        icon: <ArrowUpOutlined style={{ transform: 'rotate(180deg)' }} />,
        onClick: moveDown,
      },
    );
  }

  if (hasPermission(TasksPermissions.TASKS_PHASES_DELETE)) {
    menuItems.push(
      {
        label: 'Delete phase and tasks',
        key: '4',
        icon: <DeleteOutlined />,
        onClick: () => deletePhase(true),
      },
      {
        label: 'Delete phase and move tasks to unassigned',
        key: '5',
        icon: <DeleteOutlined />,
        onClick: () => deletePhase(false),
      },
    );
  }

  if (hasPermission(TasksPermissions.TASKS_HISTORY_READ)) {
    menuItems.push({
      label: 'Phase history',
      key: '6',
      icon: <HistoryOutlined />,
      onClick: () => sethHistoryVisible(true),
    });
  }

  const menu = <Menu items={menuItems} />;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Check isChecked={phase.isFinished} onClick={hasPermission(TasksPermissions.TASKS_PHASES_WRITE) ? changePhaseFinish : undefined} />

      <span className={`phase-header-value ${!hasPermission(TasksPermissions.TASKS_PHASES_WRITE) && 'phase-header-value--non-interactive'}`}>
        <strong>
          <Typography.Paragraph
            editable={
              hasPermission(TasksPermissions.TASKS_PHASES_WRITE)
                ? { onChange: debouncedPhaseName, triggerType: ['icon', 'text'], enterIcon: null }
                : false
            }
            style={{ display: 'inline-block', marginBottom: 0 }}
          >
            {name}
          </Typography.Paragraph>
        </strong>
      </span>
      <PhaseHeaderDates phase={phase} />

      <span className="phase-header-options">
        Estimate: {totalEstimated.toFixed(1)} h / <InputNumber value={phase.estimate} onChange={e => changeEstimate(e)} min={0} precision={1} size="small" style={{ marginRight: '16px' }} />
        {hasPermission(TasksPermissions.TASKS_PHASES_WRITE) && (
          <Dropdown overlay={menu} trigger={['click']}>
            <MoreOutlined style={{ fontSize: 22, transform: 'rotate(90deg)' }} />
          </Dropdown>
        )}
      </span>
      {historyVisible && (
        <Drawer title={phase.name} placement="right" size="large" onClose={() => sethHistoryVisible(false)} open={historyVisible}>
          <PhaseHistory id={phase.id} />
        </Drawer>
      )}
    </div>
  );
};

export default PhaseHeader;
