import useSWR from 'swr';
import { Comment, FetchResult } from '../entities';

type Result = FetchResult & { comments: Comment[] };

export function useComments(id: string, entity = 'projects'): Result {
  const { data, error, mutate } = useSWR(`/${entity}/${id}/comments`);

  return {
    comments: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
