import useSWR from 'swr'
import { FetchResult, ProjectMember } from '../entities';

type Result = FetchResult & { projects: ProjectMember[] }

export function useUserProjects(id: string): Result {
  const { data, error, mutate } = useSWR(`/users/${id}/projects`);

  return {
    projects: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}