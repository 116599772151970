import { Badge, Col, Row } from 'antd';
import React from 'react';
import { Project } from '../../../entities';
import { DashboardPages } from '../../pages/dashboard/types/types';

interface IProps {
  chatUnread: Record<string, any>;
  project: Project;
  className?: string;
  handleClickSelect: (type: DashboardPages, data: Project) => void;
  isSelected?: boolean;
}

const ProjectShort = (props: IProps) => {
  const { chatUnread, project, className, handleClickSelect, isSelected } = props;

  return (
    <div className="cursor-pointer" onClick={() => handleClickSelect(DashboardPages.PROJECT, project)}>
      <Row wrap={false} align="middle" className={className}>
        <Col flex="auto" className="chat-tab-channel">
          <span className={`text-ellipsis text-sm font-[Proxima Nova] font-[600] whitespace-nowrap ${chatUnread[project.id]?.unread && !isSelected && 'text-black'}`}>{project.client?.name} • {project.name}</span>
        </Col>
        <Col flex="none" className="tab-badges">
          {chatUnread[project.id] && !chatUnread[project.id].unreadMentions && (
            <Badge dot={Boolean(chatUnread[project.id].manualUnread)} style={{ transform: 'none', boxShadow: 'none' }} />
          )}

          {chatUnread[project.id]?.unreadMentions > 0 && (
            <Badge count={chatUnread[project.id]?.unreadMentions} style={{ transform: 'none', boxShadow: 'none' }} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProjectShort;
