import { Button, Avatar, Space, Input, Layout } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Notifications from '../../components/notifications/Notifications';
import EnvMessage from '../../components/EnvMessage';
import VersionMessage from '../../components/VersionMessage';

const { Header } = Layout;

type Props = {
  userId: number;
  profileImg: string;
  name: string;
  logout: () => void;
};

export const CustomHeader = ({ userId, profileImg, name, logout }: Props) => {
  const openSearch = () => {
    const event = new KeyboardEvent('keydown', {
      bubbles: true,
      cancelable: true,
      key: 'k',
      ctrlKey: true,
      keyCode: 75,
    });
    document.dispatchEvent(event);
  };

  return (
    <Header className="site-layout-header">
      <div className="site-layout-header-spacing">
        <div onClick={openSearch}>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<SearchOutlined style={{ color: 'rgba(255, 255, 255, 0.4)' }} />}
            className="header-search-input"
          />
        </div>
        <Space>
          <EnvMessage />
          <VersionMessage />
        </Space>
        <Space size="large">
          <div>
            <Notifications />
          </div>
          <div>
            <Space>
              <Link to={`/users/${userId}`}>
                <Avatar src={profileImg} />
              </Link>
              <Link to={`/users/${userId}`}>
                <span>{name}</span>
              </Link>
            </Space>
          </div>
          <Button onClick={logout}>Logout</Button>
        </Space>
      </div>
    </Header>
  );
};
