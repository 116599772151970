import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Link } from 'react-router-dom';
import { usePermissions } from './usePermissions/usePermissions';
import { ReactComponent as IconDashbaord } from '../images/icons/icon-dashboard-mobile.svg';
import { ReactComponent as IconMyTasks } from '../images/icons/icon-my-tasks.svg';
import { ReactComponent as IconTeam } from '../images/icons/icon-team.svg';
import { ReactComponent as IconTimesheet } from '../images/icons/icon-timesheet.svg';
import { ReactComponent as IconClients } from '../images/icons/icon-clients.svg';
import { ReactComponent as IconSettings } from '../images/icons/icon-settings.svg';
import { ReactComponent as IconReports } from '../images/icons/icon-reports.svg';
import { ReactComponent as IconPresentation } from '../images/icons/icon-presentation.svg';
import {
  ClientPermissions,
  PresentationsPermissions,
  ReportPermissions,
  SettingsPermissions,
  TasksPermissions,
  UserPermisions,
} from './usePermissions/permissions';

export function useMenu(): ItemType[] {
  const { hasPermission } = usePermissions();

  let menuItems: ItemType[] = [{ key: '', label: <Link to="/">Dashboard</Link>, icon: <IconDashbaord /> }];

  if (hasPermission(TasksPermissions.TASKS_READ)) {
    menuItems.push({ key: 'tasks', label: <Link to="/tasks">My tasks</Link>, icon: <IconMyTasks /> });
  }

  if (hasPermission(UserPermisions.USER_READ)) {
    menuItems.push({ key: 'users', label: <Link to="/users">Team</Link>, icon: <IconTeam /> });
  }

  if (hasPermission(ClientPermissions.CLIENT_VIEW)) {
    menuItems.push({ key: 'clients', label: <Link to="/clients">Clients</Link>, icon: <IconClients /> });
  }
  menuItems.push({ key: 'timesheet', label: <Link to="/timesheet">Timesheet</Link>, icon: <IconTimesheet /> });
  if (hasPermission(PresentationsPermissions.PRESENTATIONS_VIEW)) {
    menuItems.push({ key: 'presentations', label: <Link to="/presentations">Presentations</Link>, icon: <IconPresentation /> });
  }

  if (hasPermission(SettingsPermissions.SETTINGS_VIEW)) {
    menuItems.push({
      key: 'settings',
      label: <Link to="/settings">Settings</Link>,
      icon: <IconSettings />,
    });
  }

  if (hasPermission(ReportPermissions.REPORTS_VIEW)) {
    menuItems.push({
      key: 'report',
      label: <Link to="/report">Reports</Link>,
      icon: <IconReports />,
    });
  }

  return menuItems;
}
