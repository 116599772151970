import Layout from '../../layouts/authorised/Authorised';
import { useTickets } from '../../dal/useTickets';
import { PageHeader, Radio, Spin, Table } from 'antd';
import { Ticket } from '../../entities';
import moment from 'moment';
import { datePickerFormat } from '../../constants';
import { useState } from 'react';
import { nullableDataSorter } from '../../common/utils';
import TicketDetails from '../../components/tickets/TicketDetails';

type Filter = 'active' | 'all';

const filterTickets = (tasks: Ticket[], filter: Filter): Ticket[] => {
  if (!tasks) {
    return [];
  }

  if (filter === 'active') {
    return tasks.filter(t => t.status !== 'finished');
  }

  return tasks;
};

const Tickets = () => {
  const { tickets } = useTickets();
  const [filter, setFilter] = useState<Filter>('active');
  const [id, setId] = useState<string>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = (id: string) => {
    setId(id);
    setModalOpen(true);
  };

  if (!tickets) {
    return <Spin />;
  }

  return (
    <Layout>
      <PageHeader
        title="Tickets"
        extra={
          <Radio.Group onChange={e => setFilter(e.target.value)} value={filter}>
            <Radio value="active">Active</Radio>
            <Radio value="all">All</Radio>
          </Radio.Group>
        }
      />
      <Table
        className="clickable-row"
        dataSource={filterTickets(tickets, filter)}
        size="small"
        rowKey="id"
        pagination={false}
        onRow={record => {
          return {
            onClick: () => openModal(record.id),
          };
        }}
      >
        <Table.Column dataIndex="name" title="Name" sorter={(a: Ticket, b: Ticket) => nullableDataSorter(a.name, b.name)} />
        <Table.Column
          dataIndex="project"
          title="Client"
          sorter={(a: Ticket, b: Ticket) => nullableDataSorter(a.name, b.name)}
          render={(value, row: Ticket) => (
            <>
              <a href={`/clients/${row.project.client!.id}`}>{row.project.client?.name}</a> -{' '}
              <a href={`/projects/${row.project.id}`}>{row.project.name}</a>
            </>
          )}
        />
        <Table.Column
          render={(value, row: Ticket) => (
            <>
              Reported by: {row.createdBy.name}, on {moment(row.createdAt).format(datePickerFormat)}
            </>
          )}
        />
      </Table>
      {modalOpen && id && <TicketDetails id={id} setModalOpen={setModalOpen} />}
    </Layout>
  );
};

export default Tickets;
