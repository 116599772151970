import { PageHeader, Button, Table, Modal, Input } from 'antd';
import queryString from 'query-string';
import { PlusOutlined } from '@ant-design/icons';
import Layout from '../../layouts/authorised/Authorised';
import { Link, useNavigate } from 'react-router-dom';
import { useClients } from '../../dal';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Client } from '../../entities';
import { nullableDataSorter } from '../../common/utils';
import { saveFiltersToStorage, useFiltersState } from '../../common/useFiltersState';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { ClientPermissions } from '../../common/usePermissions/permissions';

const searchByPhrase = (clients: Client[], phrase: string): Client[] => {
  return clients.filter(c => c.name.toLowerCase().search(phrase.toLowerCase()) > -1);
};

const Clients = () => {
  const navigate = useNavigate();
  const params = useFiltersState('clients');
  const { hasPermission } = usePermissions();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [newClientName, setNewClientName] = useState<string>('');
  const [phrase, setPhrase] = useState<string>(params && params.s ? params.s.toString() : '');
  const { clients, mutate } = useClients();

  const openAddClient = useCallback(() => {
    setNewClientName('');
    setDrawerOpen(true);
  }, []);

  const submitNewClient = useCallback(
    async name => {
      const result = await axios.post(`/clients`, { name });
      await mutate(null, true);
      setDrawerOpen(false);
      navigate(`/clients/${result.data.data.id}`);
    },
    [navigate, mutate],
  );

  useEffect(() => {
    const filtersState = `?${queryString.stringify({
      s: !!phrase ? phrase : undefined,
    })}`;
    saveFiltersToStorage('clients', filtersState);
    navigate({
      search: filtersState,
    });
  }, [navigate, phrase]);

  return (
    <Layout>
      <PageHeader
        title="Clients"
        extra={
          hasPermission(ClientPermissions.CLIENT_CREATE) && (
            <Button icon={<PlusOutlined />} type="primary" onClick={openAddClient}>
              Add client
            </Button>
          )
        }
        onBack={() => navigate(-1)}
      >
        <Input placeholder="Search by client name" value={phrase} onChange={e => setPhrase(e.target.value)} style={{ width: '350px' }} />
      </PageHeader>
      <Table
        className="clickable-row"
        dataSource={clients ? searchByPhrase(clients, phrase) : []}
        size="small"
        rowKey="id"
        pagination={false}
        onRow={record => {
          return {
            onClick: () => {
              navigate(`/clients/${record.id}`);
            },
          };
        }}
      >
        <Table.Column dataIndex="autoCode" title="Code" sorter={(a: Client, b: Client) => nullableDataSorter(a.autoCode, b.autoCode)} />
        <Table.Column
          dataIndex="name"
          title="Name"
          render={(value, row: any) => <Link to={`/clients/${row.id}`}>{value}</Link>}
          sorter={(a: Client, b: Client) => nullableDataSorter(a.name, b.name)}
        />
      </Table>
      <Modal title="Add client" open={drawerOpen} onOk={() => submitNewClient(newClientName)} onCancel={() => setDrawerOpen(false)}>
        <Input placeholder="Enter new client name" value={newClientName} onChange={e => setNewClientName(e.target.value)} />
      </Modal>
    </Layout>
  );
};

export default Clients;
