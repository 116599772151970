import useSWR from 'swr';
import { buildTimesheetQueryParams } from '../common/utils';
import { FetchResult, TimesheetSelfResponse } from '../entities';

type Result = FetchResult & TimesheetSelfResponse;

export function useTimesheets(startDate?: string, monthlyView?: boolean): Result {
  const queryString = buildTimesheetQueryParams(startDate, monthlyView);
  const { data, error, mutate } = useSWR(`/timesheets?${queryString}`);

  return {
    timesheets: data?.data,
    user: data?.user,
    weekSubmition: data?.weekSubmition,
    monthSubmition: data?.monthSubmition,
    pagination: data?.pagination,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
