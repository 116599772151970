import { message, Switch, Row, Col, Divider } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { User } from '../../entities';

interface Props {
  user: User;
}

const UserActiveSwitcher = ({ user }: Props) => {
  const [checked, setChecked] = useState<boolean>(user.active);
  const onChange = async (checked: boolean) => {
    setChecked(checked);
    await axios.patch(`/users/${user.id}`, { active: checked });
    message.success('Saved');
  };

  return (
    <>
      <Divider orientation="left">User active</Divider>
      <Row gutter={16} style={{ marginBottom: 24 }}>
        <Col>Is user active?</Col>
        <Col>
          <Switch checked={checked} onChange={onChange} />
        </Col>
      </Row>
    </>
  );
};

export default UserActiveSwitcher;
