import { Button, Col, Dropdown, MenuProps, Row, Space } from 'antd';

import { ReactComponent as ProjectAdvIcon } from '../../../static/images/adv-view.svg';
import { ReactComponent as ProjectShortIcon } from '../../../static/images/normal-view.svg';
import { ReactComponent as AccordionIcon } from '../../../static/images/accordion-icon.svg';
import { ReactComponent as PlusIcon } from '../../../static/images/plus.svg';

import { CREATION_MENU_ACTIONS, ProjectsDisplay } from '../types/types';
import { creationMenuItems } from '../types/constants';

interface ProjectHeaderProps {
  projectsDisplay: string;
  projectAccordionState: boolean;
  showProjectAccordionInShortMode: boolean;
  onProjectDisplayChange: (projectDisplay: ProjectsDisplay) => void;
  onProjectAccordionStateChange: (projectAccordionState: boolean) => void;
  onCreateProject: () => void;
  onCreateMessage: () => void;
  onCreateChannel: () => void;
}

const SidebarHeader = ({
  projectsDisplay,
  projectAccordionState,
  showProjectAccordionInShortMode,
  onProjectDisplayChange,
  onProjectAccordionStateChange,
  onCreateProject,
  onCreateMessage,
  onCreateChannel,
}: ProjectHeaderProps) => {
  const handleClickCreationMenu: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case CREATION_MENU_ACTIONS.PROJECT: {
        onCreateProject();
        break;
      }
      case CREATION_MENU_ACTIONS.MESSAGES: {
        onCreateMessage();
        break;
      }
      case CREATION_MENU_ACTIONS.CHANNEL: {
        onCreateChannel();
        break;
      }
    }
  };

  return (
    <Row className="!cursor-default pt-[18px] pb-[4px] items-center flex-nowrap mb-2 mx-[8px]">
      <Col className="overflow-hidden text-ellipsis">
        <span
          onClick={() => {
            if (projectsDisplay === 'short') {
              localStorage.setItem('project-accordion', !projectAccordionState + '');
              onProjectAccordionStateChange(!projectAccordionState);
            }
          }}
          className="overflow-hidden text-ellipsis cursor-pointer !m-0 font-[Proxima Nova] font-[700] text-[#1D1D1D] text-[21px] !pl-[8px] text-ellipsis overflow-hidden whitespace-nowrap"
        >
          Projects
        </span>
      </Col>
      <Col id="sidebar-col" className="ml-[10px]" flex="auto">
        <div className="flex items-center p-[6px] rounded-[6px] bg-[#ECEFF3] w-fit">
          <div
            className={`!cursor-pointer w-[24px] h-[24px] rounded-[6px] flex justify-center items-center text-[#4D546E] hover:text-[#4F67FF] ${
              projectsDisplay === ProjectsDisplay.SHORT && 'selected-project-type'
            }`}
            onClick={() => {
              localStorage.setItem('project-display-mode', ProjectsDisplay.SHORT);
              onProjectDisplayChange(ProjectsDisplay.SHORT);
            }}
          >
            <ProjectShortIcon />
          </div>
          <div
            className={`!cursor-pointer w-[24px] h-[24px] rounded-[6px] flex justify-center items-center text-[#4D546E] hover:text-[#4F67FF] ${
              projectsDisplay === ProjectsDisplay.ADVANCED && 'selected-project-type'
            }`}
            onClick={() => {
              localStorage.setItem('project-display-mode', ProjectsDisplay.ADVANCED);
              onProjectDisplayChange(ProjectsDisplay.ADVANCED);
            }}
          >
            <ProjectAdvIcon />
          </div>
        </div>
      </Col>
      <Col>
        <Dropdown menu={{ items: creationMenuItems, onClick: handleClickCreationMenu }} className=" rounded-b-[16px] ">
          <div onClick={e => e.preventDefault()}>
            <Space>
              <div className={`flex justify-center items-center create-projects-button relative h-[36px] z-[1] w-[36px] min-w-[36px]`}>
                <PlusIcon className="plus-icon w-4 h-4 !text-[#4F67FF]" />
              </div>
            </Space>
          </div>
        </Dropdown>
      </Col>
      <Col>
        {!!(projectsDisplay === ProjectsDisplay.SHORT && showProjectAccordionInShortMode) && (
          <Button
            className="button-without-bg"
            type="text"
            icon={<AccordionIcon className={` transition-all rotate-180 ${projectAccordionState && '!rotate-[-180]'}`} />}
            onClick={() => {
              localStorage.setItem('project-accordion', !projectAccordionState + '');
              onProjectAccordionStateChange(!projectAccordionState);
            }}
          />
        )}
      </Col>
    </Row>
  );
};

export default SidebarHeader;
