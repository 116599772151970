import useSWR from 'swr';
import { FetchResult } from '../entities';

type Result = FetchResult & { templates: any[] };

export function usePresentationTemplates(): Result {
  const { data, error, mutate } = useSWR(`/presentations/templates`);

  return {
    templates: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
