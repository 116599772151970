import { formatDateTime } from '../../common/utils';
import { Notification } from '../../entities';

type Props = {
  notification: Notification;
};

const NotificationLinkAndDate = ({ notification }: Props) => {
  let link = <></>;
  if (notification.linkType === 'project') {
    link = <a href={`/projects/${notification.entityId}?defaultTab=tasks`}>{notification.title}</a>;
  }

  if (notification.linkType === 'tasks') {
    link = <a href="/tasks">{notification.title}</a>;
  }

  if (notification.linkType === 'timesheet') {
    link = <a href="/timesheet">{notification.title}</a>;
  }

  if (notification.linkType === 'ticket') {
    link = <a href="/tickets">{notification.title}</a>;
  }

  return (
    <>
      {link} <br /> <span style={{ color: 'grey', fontSize: 11 }}>{formatDateTime(notification.createdAt)}</span>
    </>
  );
};

export default NotificationLinkAndDate;
