import { Col, Divider, Drawer, message, Row, Spin } from 'antd';
import { useCallback } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Ticket } from '../../entities';
import axios from 'axios';
import TicketHours from './TicketHours';
import moment from 'moment';
import TicketFinanciallyClosed from './TicketFinanciallyClosed';
import TicketStatusPicker from './TicketStatusPicker';
import TicketTasks from './TicketTasks';
import { datePickerFormat } from '../../constants';
import { useTicket } from '../../dal/useTicket';

type Props = {
  id: string;
  setModalOpen: (v: boolean) => void;
};

const TicketDetails = ({ id, setModalOpen }: Props) => {
  const { ticket, mutate: mutateTicket } = useTicket(id);

  const handleChange = useCallback(
    async (id: string, values: Partial<Ticket>) => {
      const resp = await axios.patch(`/tickets/${id}`, values);
      await mutateTicket(resp.data);
      message.success('Ticket updated');
    },
    [mutateTicket],
  );

  const closeModal = () => setModalOpen(false);

  return (
    <Drawer title={ticket?.name || ''} placement="right" onClose={closeModal} open={true} bodyStyle={{ padding: '0 0 8px 16px' }} width={1000}>
      {!ticket ? (
        <Spin />
      ) : (
        <>
          <p style={{ fontSize: '16px', marginTop: 15, marginBottom: 30 }}>{ticket.description}</p>
          <Row gutter={16}>
            <Col span="4">Status</Col>
            <Col span="4">Finish date</Col>
            <Col span="6">Estimated hours</Col>
            <Col span="6">Financially closed</Col>
          </Row>
          <Row gutter={16}>
            <Col span="4">
              <TicketStatusPicker onChange={handleChange} ticket={ticket} />
            </Col>
            <Col span="4">{moment(ticket.endDate).format(datePickerFormat)}</Col>
            <Col span="6">
              <TicketHours onChange={handleChange} ticket={ticket} />
            </Col>
            <Col span="6">
              <TicketFinanciallyClosed onChange={handleChange} ticket={ticket} />
            </Col>
          </Row>
          <Divider />
          <TicketTasks ticket={ticket} />
        </>
      )}
    </Drawer>
  );
};

export default TicketDetails;
