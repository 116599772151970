import useSWR from 'swr';
import { FetchResult, Ticket } from '../entities';

type Result = FetchResult & { ticket: Ticket };

export function useTicket(id: string): Result {
  const { data, error, mutate } = useSWR(`/tickets/${id}`);

  return {
    ticket: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
