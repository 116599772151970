import useSWR from 'swr';
import { FetchResult, SalesReportItem } from '../entities';

type Result = FetchResult & { report: SalesReportItem[]; pagination: any; totalYear: number; totalMonth: number };

export function useSalesReport(currentMonth: string | null): Result {
  const { data, error, mutate } = useSWR(`/project-finances/sales-report?currentMonth=${currentMonth || ''}`);

  return {
    report: data?.data,
    totalYear: data?.totalYear,
    totalMonth: data?.totalMonth,
    pagination: data?.pagination,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
