import { Select } from 'antd';
import { useProjects } from '../../dal';
import { Project } from '../../entities';

function mapProject(p: Project) {
  return {
    value: p.id,
    label: `${p.client?.name || 'No client'} - ${p.name}`,
  };
}

const ProjectSelector = (props: any) => {
  const { projects = [] } = useProjects();
  const closedProjects = projects.filter(p => p.status === 'finished').map(mapProject);
  const openProjects = projects.filter(p => p.status === 'active' || p.status === 'draft').map(mapProject);

  return (
    <Select
      {...props}
      showSearch
      placeholder="Select project"
      options={[
        { label: 'Open', options: openProjects },
        { label: 'Closed', options: closedProjects },
      ]}
      filterOption={(input, option) => {
        return `${option?.label}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    />
  );
};

export default ProjectSelector;
