import useSWR from 'swr';
import { FetchResult, Task } from '../entities';

type Result = FetchResult & { tasks: Task[] };

export function useTicketTasks(id?: string): Result {
  const { data, error, mutate } = useSWR(id ? `/tasks/${id}/ticket` : null);

  return {
    tasks: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
