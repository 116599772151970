import useSWR from 'swr';
import { CashFlowReportItem, FetchResult } from '../entities';

type Result = FetchResult & { 
  report: CashFlowReportItem[]; 
  result: any, 
  pagination: any;
};

export function useCashFlowReport(currentYear: number | null): Result {
  const { data, error, mutate } = useSWR(`/project-finances/cashflow-report?currentYear=${currentYear || ''}`);

  
  var result = data?.data.reduce((res, cur: CashFlowReportItem) => {
    if (!res[cur.projectCode]) {
      res[cur.projectCode] = {
        projectName: `${cur.projectCode} ${cur.client} - ${cur.project}`,
        payments: {
          1: [],
          2: [],
          3: [],
          4: [],
          5: [],
          6: [],
          7: [],
          8: [],
          9: [],
          10: [],
          11: [],
          12: []
        }
        
      };
    }
    res[cur.projectCode].payments[cur.paymentMonth].push(cur);
    return res;
  }, {});

  return {
    report: data?.data,
    result,
    pagination: data?.pagination,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
