import { Dropdown, Menu, Tag } from 'antd';
import { Ticket, TicketStatus } from '../../entities';

export const getTicketStateTagColor = (ticketState: TicketStatus): string => {
  switch (ticketState) {
    case 'new':
      return 'grey';
    case 'estimated':
      return 'blue';
    case 'approved':
      return 'cyan';
    case 'finished':
      return 'green';
    default:
      return 'grey';
  }
};

export const getTicketStateTagCopy = (ticketState: TicketStatus): string => {
  switch (ticketState) {
    case 'new':
      return 'New';
    case 'estimated':
      return 'Estimated';
    case 'approved':
      return 'Approved';
    case 'finished':
      return 'Finished';
    default:
      return 'New';
  }
};

type MenuClick = {
  key: string;
};

interface Props {
  ticket: Ticket;
  onChange: (id: string, values: Partial<Ticket>) => void;
}

const TicketStatusPicker = ({ ticket, onChange }: Props) => {
  const handleMenuClick = (e: MenuClick) => {
    onChange(ticket.id, { status: e.key as TicketStatus });
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          key: 'new',
          label: 'New',
        },
        {
          key: 'estimated',
          label: 'Estimated',
        },
        {
          key: 'approved',
          label: 'Approved',
        },
        {
          key: 'finished',
          label: 'Finished',
        },
      ]}
    />
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Tag color={getTicketStateTagColor(ticket.status)} className="cursor-pointer">
        {getTicketStateTagCopy(ticket.status)}
      </Tag>
    </Dropdown>
  );
};

export default TicketStatusPicker;
