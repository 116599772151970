import React from 'react';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import Results from './Results';
import SearchInput from './SearchInput';
import './Search.css';
import RecentSearches from './RecentSearches';
import { useDebounce } from 'use-debounce';

const Search = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [value] = useDebounce(query, 500);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 75) {
        if (isModalVisible) {
          handleClose();
        } else {
          setIsModalVisible(true);
        }
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, [isModalVisible]);

  const handleClose = () => {
    setQuery('');
    setIsModalVisible(false);
  };

  if (!isModalVisible) {
    return null;
  }

  return (
    <Modal
      open={true}
      onCancel={handleClose}
      footer={null}
      width="1010px"
      modalRender={() => {
        return (
          <div className="modal-search">
            <div className="modal-search-input-container">
              <SearchInput query={query} setQuery={setQuery} onClose={handleClose} />
            </div>
            <div className="modal-search-results-container">
              <RecentSearches query={value} setQuery={setQuery} />
              {value && <Results query={value} />}
            </div>
          </div>
        );
      }}
    />
  );
};

export default Search;
