import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export type FilterTypes = 'clients' | 'projects' | 'clientsproject' | 'tasks' | 'allprojects';

export function parseParams(search: string) {
  return queryString.parse(search, { parseNumbers: true, parseBooleans: true });
}

export function useFiltersState(page: FilterTypes) {
  const { search } = useLocation();
  const fromUrl = window.location.href.includes('?');
  if (fromUrl) {
    const params = parseParams(search);
    return params;
  }

  const paramsFromStorage = localStorage.getItem(`filters_${page}`);
  return paramsFromStorage ? queryString.parse(paramsFromStorage) : {};
}

export function saveFiltersToStorage(page: FilterTypes, value: string) {
  localStorage.setItem(`filters_${page}`, value);
}
