import { Task, TaskState } from '../../entities';
import TaskStatePicker from './TaskStatePicker';

export const getTaskStateTagColor = (taskState: TaskState): string => {
  switch (taskState) {
    case 'new':
      return 'grey';
    case 'in progress':
      return 'blue';
    case 'stuck':
      return 'red';
    case 'finished':
      return 'green';
    default:
      return 'grey';
  }
};

export const getTaskStateTagCopy = (taskState: TaskState): string => {
  switch (taskState) {
    case 'new':
      return 'New';
    case 'in progress':
      return 'In progress';
    case 'stuck':
      return 'Stuck';
    case 'finished':
      return 'Finished';
    default:
      return 'New';
  }
};

interface Props {
  task: Task;
  onTaskChange: (data: Partial<Task>) => Promise<void>;
}

const TaskStateTag = ({ task, onTaskChange }: Props) => {
  const handleChange = (state: TaskState) => {
    onTaskChange({ id: task.id, state });
  };

  return <TaskStatePicker onChange={handleChange} defaultColor={getTaskStateTagColor(task.state)} defaultValue={getTaskStateTagCopy(task.state)} />;
};

export default TaskStateTag;
