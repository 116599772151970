import { Input, message, Modal } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useProjectTasks } from '../../dal/useProjectTasks';
import { useSettings } from '../../dal/useSettings';
import { PhaseTasksSetting } from '../../pages/settingsPhases';

type Props = { projectId: string; onClose: () => void };

const SaveProjectAsTemplate = ({ projectId, onClose }: Props) => {
  const { phases } = useProjectTasks(projectId);
  const { settings, key } = useSettings<PhaseTasksSetting>('phases_tasks');
  const [name, setName] = useState('');

  const saveTemplate = async () => {
    const checkIfExists = settings!?.templates.filter(t => t.name === name).length > 0;
    if (checkIfExists) {
      message.warning('Template with that name already exists');
    } else {
      const data = phases.map(p => ({ name: p.name, tasks: p.tasks?.map(t => t.name) }));
      await axios.post(`/settings`, { key, value: { templates: settings ? [...settings!.templates, { name, phases: data }] : [{ name, phases: data }] } });
      message.success('Saved successfully');
      onClose();
    }
  };

  return (
    <Modal title="Enter name for template" open={true} onCancel={onClose} onOk={saveTemplate}>
      <Input value={name} style={{ width: '100%' }} onChange={e => setName(e.target.value)} />
    </Modal>
  );
};

export default SaveProjectAsTemplate;
