import { Spin } from 'antd';
import Layout from '../../layouts/authorised/Authorised';
import ChatWindow from '../../components/chat/ChatWindow';
import { useChatContext } from '../../common/chatContext';

const Users = () => {
  const { chatReady } = useChatContext();

  if (!chatReady)
    return (
      <Layout isFullPage>
        <Spin />
      </Layout>
    );

  return (
    <Layout isFullPage>
      <ChatWindow chatView="direct-chats" active={true} allowComment height="100%" />
    </Layout>
  );
};

export default Users;
