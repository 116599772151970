import { Badge, Button, Drawer, List, Space, Typography } from 'antd';
import { BellOutlined, CheckOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useNotifications } from '../../dal/useNotifications';
import NotificationsSettings from './NotificationsSettings';
import axios from 'axios';
import './Notifications.css';
import NotificationLinkAndDate from './NotificationLinkAndDate';

const Notifications = () => {
  const { notifications = [], mutate } = useNotifications();
  const badgesCount = notifications?.filter(n => !n.isRead).length || 0;
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleMarkAllAsRead = async () => {
    for (const notification of notifications) {
      await markAsRead(notification.id);
    }
    await mutate(null, true);
  };

  const markAsRead = async (id: string) => {
    await axios.patch(`notifications/${id}/read`);
  };

  const markAndMutateRead = async (id: string) => {
    await axios.patch(`notifications/${id}/read`);
    await mutate(null, true);
  };

  return (
    <>
      <div onClick={showDrawer} style={{ display: 'flex', cursor: 'pointer' }}>
        <Badge count={badgesCount}>
          <BellOutlined style={{ color: 'white', fontSize: '24px' }} />
        </Badge>
      </div>

      <Drawer title="Notifications" placement="right" onClose={onClose} open={visible} bodyStyle={{ padding: '0 0 8px 16px' }}>
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div style={{ overflow: 'scroll', paddingRight: 8 }}>
            {notifications && notifications.length === 0 && <Typography.Text>No notifications found.</Typography.Text>}
            {notifications.map(n => (
              <List.Item key={n.id} actions={n.isRead ? [] : [<Button onClick={() => markAndMutateRead(n.id)} icon={<CheckOutlined />} />]}>
                <List.Item.Meta title={<NotificationLinkAndDate notification={n} />} description={n.message} />
              </List.Item>
            ))}
          </div>
          <div style={{ paddingTop: 8 }}>
            <Space direction="horizontal">
              <Button onClick={handleMarkAllAsRead}>Mark all as read</Button>
              <NotificationsSettings />
            </Space>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Notifications;
