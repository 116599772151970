import { useDebouncedCallback } from 'use-debounce';
import { useState } from 'react';
import { Ticket } from '../../entities';
import { InputNumber } from 'antd';

interface Props {
  ticket: Ticket;
  onChange: (id: string, values: Partial<Ticket>) => void;
}

const TicketHours = ({ ticket, onChange }: Props) => {
  const [hours, setHours] = useState<number | undefined>(ticket.hours || undefined);
  const debounced = useDebouncedCallback(hours => {
    setHours(hours);
    onChange(ticket.id, { hours });
  }, 1000);

  return <InputNumber defaultValue={hours} onChange={v => debounced(v)} style={{ width: 100 }} />;
};

export default TicketHours;
