import { Radio } from 'antd';
import { Project } from '../../entities';

export type Filters = 'all' | 'active' | 'finished' | 'draft';

export const filterProjects = (projects: Project[], filter: Filters, hasPermissionForDraft: boolean, activeId?: string): Project[] => {
  if (!projects) return [];
  if (filter === 'all') {
    if (hasPermissionForDraft) return projects;
    return projects.filter(project => project.status !== 'draft' || activeId === project.id);
  }
  return projects.filter(project => project.status === filter || activeId === project.id);
};

export const ProjectFilters = ({
  onChange,
  value,
  hasPermissionForDraft,
}: {
  onChange: (v: Filters) => void;
  value: Filters;
  hasPermissionForDraft: boolean;
}) => (
  <Radio.Group onChange={e => onChange(e.target.value)} value={value}>
    <Radio value="active">Active</Radio>
    <Radio value="finished">Finished</Radio>
    {hasPermissionForDraft && <Radio value="draft">Draft</Radio>}
    <Radio value="all">All</Radio>
  </Radio.Group>
);
