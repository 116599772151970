import { List, Comment as CommentComponent, Tooltip, Row, Col, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { useState } from 'react';
import { TasksPermissions } from '../../common/usePermissions/permissions';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { formatDateTime } from '../../common/utils';
import { Comment } from '../../entities';

const CommentInput = ({
  comment,
  setComment,
  onSubmit,
  onCancel,
}: {
  comment: string;
  setComment: (text: string) => void;
  onSubmit: () => void;
  onCancel?: () => void;
}) => (
  <Row gutter={[16, 16]}>
    <Col span="20">
      <TextArea rows={4} placeholder="Send message" value={comment} onChange={e => setComment(e.target.value)} />
    </Col>
    <Col span="4">
      <Button type="primary" style={{ display: 'block', margin: '0 auto', marginTop: onCancel ? '15px' : '25%' }} onClick={onSubmit}>
        Send
      </Button>
      {onCancel ? (
        <Button style={{ display: 'block', margin: '10px auto' }} onClick={onCancel}>
          Cancel
        </Button>
      ) : null}
    </Col>
  </Row>
);

type Props = {
  userId?: number;
  comments: Comment[];
  onEdit: (text: string, id: string) => void;
  onSubmit: (text: string) => void;
};

const Comments = ({ userId, comments, onSubmit, onEdit }: Props) => {
  const { hasPermission } = usePermissions();
  const [comment, setComment] = useState<string>('');
  const [editComment, setEditComment] = useState<string>('');
  const [editCommentId, setEditCommentId] = useState<string | null>(null);

  const submit = () => {
    onSubmit(comment);
    setComment('');
  };

  const edit = () => {
    onEdit(editComment, editCommentId as string);
    cancelEdit();
  };

  const setEditMode = (id: string, text: string) => {
    setEditCommentId(id);
    setEditComment(text);
  };

  const cancelEdit = () => {
    setEditComment('');
    setEditCommentId(null);
  };

  return (
    <>
      {hasPermission(TasksPermissions.TASKS_WRITE) && <CommentInput comment={comment} setComment={setComment} onSubmit={submit} />}
      <List
        itemLayout="horizontal"
        dataSource={comments}
        renderItem={(item: Comment) => (
          <li>
            {editCommentId === item.id && hasPermission(TasksPermissions.TASKS_WRITE) ? (
              <CommentInput comment={editComment} setComment={setEditComment} onSubmit={edit} onCancel={cancelEdit} />
            ) : (
              <CommentComponent
                actions={item.author.id === userId ? [<span onClick={() => setEditMode(item.id, item.text)}>Edit</span>] : undefined}
                author={item.author.name}
                avatar={item.author.picture}
                content={item.text}
                style={{ whiteSpace: 'pre-wrap' }}
                datetime={
                  <Tooltip title={formatDateTime(item.createdAt)}>
                    <span>{moment(item.createdAt).fromNow()}</span>
                  </Tooltip>
                }
              />
            )}
          </li>
        )}
      />
    </>
  );
};

export default Comments;
