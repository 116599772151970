import { useCallback, useEffect, useState } from 'react';
import { InputNumber, Space, Descriptions, Button, Modal, Form, Row, Col, Divider, DatePicker, Popconfirm, Input, Tag } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { SwapOutlined, MinusCircleOutlined, PlusOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import MoneyInput from '../shared/MoneyInput';
import { ProjectFinance } from '../../entities';
import { formatCurrency } from '../../common/utils';
import { datePickerFormat } from '../../constants';

type Props = {
  finance: ProjectFinance;
  timesheetTotals: {
    cost: number;
    minutes: number
  },
  saved: boolean;
  onUpdate: (formData: any) => void;
  onDesiredProditUpdate: (value: number) => void;
}

export const FinanceHeader = ({ finance, timesheetTotals, onUpdate, onDesiredProditUpdate, saved }: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [budgetForm] = useForm();
  const [desiredBudget, setDesiredBudget] = useState<number>(finance.desiredBudget);
  const [desiredBudgetValue, setDesiredBudgetValue] = useState<number>((finance.budget * finance.desiredBudget / 100));

  const submitAndCloseModal = useCallback(async () => {
    await onUpdate({
      ...budgetForm.getFieldsValue()
    });
    setModalOpen(false);
  }, [budgetForm, onUpdate]);

  const onCancel = useCallback(() => {
    setModalOpen(false);
    budgetForm.resetFields();
  }, [budgetForm]);

  useEffect(() => {
    budgetForm.resetFields();
  }, [budgetForm]);

  useEffect(() => {
    setDesiredBudgetValue(finance.budget * finance.desiredBudget / 100)
  }, [finance.budget, finance.desiredBudget]);

  const updateDesiredProfit = useCallback(async (newValue) => {
    setDesiredBudget(newValue);
    onDesiredProditUpdate(newValue);
  }, [onDesiredProditUpdate]);

  return <>
    <Descriptions bordered size="small" column={4}>
      <Descriptions.Item label="Project Total">
        <Space>
          <MoneyInput
            style={{ width: '90px' }}
            value={finance.budget}
            readOnly={true}
            bordered={false} />
          <Button type="link" onClick={() => setModalOpen(true)}>Update</Button>
        </Space>
      </Descriptions.Item>
      <Descriptions.Item label="Budget">
        <Space>
          <InputNumber
            style={{ width: '65px' }}
            controls={false}
            min={0} max={100}
            value={desiredBudget}
            onChange={e => {
              const v = e || 0;
              updateDesiredProfit(v);
              setDesiredBudgetValue(finance.budget * v / 100)
            }}
            formatter={(value) => `${value}%`} />
          <SwapOutlined />
          <MoneyInput
            style={{ width: '90px' }}
            onChange={e => {
              const v = +(e || '0');
              setDesiredBudgetValue(v);
              if (finance.budget > 0) {
                updateDesiredProfit(v / finance.budget * 100);
              }
            }}
            value={desiredBudgetValue} />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item label="AVG Hourly Rate">
        {formatCurrency(timesheetTotals.cost / (timesheetTotals.minutes / 60))}
      </Descriptions.Item>
      <Descriptions.Item label="Status">
        <div style={{ width: '80px' }}>
          {saved
            ? <Tag icon={<CheckCircleOutlined />} color="success">Saved</Tag>
            : <Tag icon={<ExclamationCircleOutlined />} color="warning">Unsaved</Tag>}
        </div>
      </Descriptions.Item>
    </Descriptions>
    {modalOpen && <Modal title="Project Budget" open={modalOpen} onOk={() => submitAndCloseModal()} onCancel={onCancel} width={800}>
      <Form autoComplete="off" initialValues={finance} form={budgetForm}>
        <Form.List name="budgetDetails">
          {(fields: any, { add, remove }) => (
            <>
              <Row gutter={16}>
                <Col span="8">Value</Col>
                <Col span="8">Contract Signed Date</Col>
              </Row>
              <Divider />
              {fields.map((field: any) => (
                <Row key={field.key} gutter={16}>
                  <Col span="6">
                    <Form.Item {...field} name={[field.name, 'value']} rules={[{ required: true, message: 'Missing value' }]}>
                      <MoneyInput style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span="8">
                    <Form.Item {...field} name={[field.name, 'contractSignedDate']}>
                      <DatePicker format={datePickerFormat} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span="8">
                    <Form.Item {...field} name={[field.name, 'description']}>
                      <Input style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span="2">
                    <Popconfirm title="Are you sure ?" onConfirm={() => remove(field.name)} okText="Yes" cancelText="No">
                      <Button type="text" icon={<MinusCircleOutlined />} />
                    </Popconfirm>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col span="22">
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add budget
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>}
  </>;
}
