import Layout from '../../layouts/authorised/Authorised';
import { useTasks } from '../../dal';
import TasksTableContainer from '../../components/tasks/TasksTableContainer';
import axios from 'axios';
import { Task } from '../../entities';

const Tasks = () => {
  const { myTasks, projectsTasks, mutate } = useTasks();

  const handleTaskChange = async (values: Partial<Task>) => {
    const resp = await axios.put(`/tasks/${values.id}`, { ...values, id: undefined });
    await mutate(resp.data);
  };

  return (
    <Layout>
      <TasksTableContainer data={myTasks} title="Unassigned / Personal tasks" personal={true} onTaskChange={handleTaskChange} />
      <TasksTableContainer data={projectsTasks} title="Project tasks" personal={false} showProjectSortOption={true} onTaskChange={handleTaskChange} />
    </Layout>
  );
};

export default Tasks;
