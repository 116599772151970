import { datePickerFormat } from '../../constants';
import moment from 'moment';

type Props = {
  date?: Date | null;
  label: string;
};

const ProjectDate = ({ date, label }: Props) => (
  <div>
    {label}: {date ? moment(date).format(datePickerFormat) : 'not set'}
  </div>
);

export default ProjectDate;
