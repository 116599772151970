import { Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';

interface Props {
  onEdit: (comment: string) => void;
  onClose: () => void;
}

const ApprovalRejection = ({ onEdit, onClose }: Props) => {
  const [comment, setComment] = useState<string>('');

  return (
    <Modal title="Reject" open={true} onCancel={onClose} onOk={() => onEdit(comment)}>
      <TextArea rows={4} placeholder="Comment" value={comment} onChange={e => setComment(e.target.value)} />
    </Modal>
  );
};

export default ApprovalRejection;
