import { MenuProps } from "antd";
import { CREATION_MENU_ACTIONS } from "./types";

export const creationMenuItems: MenuProps['items'] = [
  {
    key: CREATION_MENU_ACTIONS.PROJECT,
    label: 'New project',
  },
  {
    key: CREATION_MENU_ACTIONS.MESSAGES,
    label: 'New direct message',
  },
  {
    key: CREATION_MENU_ACTIONS.CHANNEL,
    label: 'New channel',
  },
];