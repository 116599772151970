import useSWR from 'swr';
import { FetchResult, TimesheetMonth } from '../entities';

type Result = FetchResult & { payroll: TimesheetMonth[] };

export function usePayroll(currentMonth: string): Result {
  const { data, error, mutate } = useSWR(`/payroll?currentMonth=${currentMonth}`);

  return {
    payroll: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
