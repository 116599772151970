import { useCallback, useState } from 'react';
import { Input, Descriptions, Space, Collapse, Timeline } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { Task } from '../../entities';
import { displayHoursFromNumber, formatDate, getTotalHours } from '../../common/utils';
import TaskProject from './TaskProject';
import moment from 'moment';
import { datePickerFormat } from '../../constants';
import { useComments } from '../../dal/useComments';
import Comments from '../shared/Comments';
import axios from 'axios';
import { useSession } from '../../dal';
import TaskAssignees from './TaskAssignees';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { TasksPermissions, UserPermisions } from '../../common/usePermissions/permissions';
import TaskHistory from './TaskHistory';
import ChangeTasksPhase from './ChangeTasksPhase';
import { useTaskTimesheet } from '../../dal/useTaskTimesheet';

const { TextArea } = Input;

interface Props {
  task: Task;
  onTaskChange: (data: Partial<Task>) => Promise<void>;
}

const TaskDetails = ({ task, onTaskChange }: Props) => {
  const { timesheets = [] } = useTaskTimesheet(task.id);

  const { session } = useSession();
  const { hasPermission } = usePermissions();

  const [description, setDescription] = useState<string>(task.description);
  const { comments, mutate } = useComments(task.id, 'tasks');

  const debounced = useDebouncedCallback(description => {
    setDescription(description);
    onTaskChange({ id: task.id, description });
  }, 250);

  const submitComment = useCallback(
    async text => {
      await axios.post(`/tasks/${task.id}/comments`, { text });
      await mutate(null, true);
    },
    [task, mutate],
  );

  const editComment = async (text: string, commentId: string) => {
    const result = await axios.patch(`/tasks/${task.id}/comments/${commentId}`, { text });
    await mutate(result.data.data, true);
  };

  return (
    <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
      <Collapse bordered={false} defaultActiveKey="1">
        <Collapse.Panel header="Task information" key="1">
          <Descriptions bordered size="small">
            <Descriptions.Item label="Due date" span={3}>
              {moment(task.dueDate).format(datePickerFormat)}
            </Descriptions.Item>
            <Descriptions.Item label="Project" span={3}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <TaskProject task={task} onTaskChange={onTaskChange} />
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label="Assignees" span={3}>
              <TaskAssignees task={task} onTaskChange={onTaskChange} />
            </Descriptions.Item>
            <Descriptions.Item label="Estimate" span={3}>
              {task.estimate} hours
            </Descriptions.Item>
            <Descriptions.Item label="Created by" span={3}>
              {task.createdBy.name}
            </Descriptions.Item>
            <Descriptions.Item label="Created at" span={3}>
              {formatDate(task.createdAt)}
            </Descriptions.Item>
            <Descriptions.Item label="Description" span={3}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <TextArea rows={4} defaultValue={description} onChange={e => debounced(e.target.value)} />
              </Space>
            </Descriptions.Item>
          </Descriptions>
        </Collapse.Panel>
        {hasPermission(TasksPermissions.TASKS_PHASES_WRITE) && (
          <Collapse.Panel header="Phase" key="2">
            <ChangeTasksPhase task={task} />
          </Collapse.Panel>
        )}
        {hasPermission(UserPermisions.USER_TIMESHEET_READ) && (
          <Collapse.Panel header="Timesheet" key="3">
            <h3>Total: {displayHoursFromNumber(getTotalHours(timesheets))}</h3>
            <Timeline mode="left">
              {timesheets.map(t => (
                <Timeline.Item label={moment(t.entryDate).format('ll')} key={t.id}>
                  {t.user.name} tracked {t.hours} hours.
                  <br />
                  Description: {t.description}
                </Timeline.Item>
              ))}
            </Timeline>
            <br />
          </Collapse.Panel>
        )}
        <Collapse.Panel header="Comments" key="4">
          <Comments comments={comments} onSubmit={submitComment} onEdit={editComment} userId={session?.id} />
        </Collapse.Panel>
        {hasPermission(TasksPermissions.TASKS_HISTORY_READ) && (
          <Collapse.Panel header="History" key="5">
            <TaskHistory id={task.id} />
          </Collapse.Panel>
        )}
      </Collapse>
    </div>
  );
};

export default TaskDetails;
