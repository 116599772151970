import useSWR from 'swr';
import { FetchResult, UserRates } from '../entities';

type Result = FetchResult & { rates: UserRates[] };

export function useUserRates(id?: number | string, effectiveDate?: string): Result {
  const { data, error, mutate } = useSWR(`/users/${id}/rates${effectiveDate ? `?effectivedate=${effectiveDate}` : ''}`);

  return {
    rates: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
