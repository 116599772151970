import useSWR from 'swr';
import { FetchResult, TimesheetResponse } from '../entities';

type Result = FetchResult & TimesheetResponse;

export function useUsersTimesheet({ userId, projectId, startDate, monthlyView }: { userId?: number | string; projectId?: string; startDate?: string, monthlyView?: boolean }): Result {
  const projectIdQuery: any = projectId && projectId !== 'all' ? { projectId } : {};
  const startDateQuery: any = startDate ? { startDate } : {};
  const monthlyViewQuery: any = monthlyView ? { monthlyView } : {};
  const usp = new URLSearchParams({ ...startDateQuery, ...projectIdQuery, ...monthlyViewQuery });
  usp.sort();
  const queryString = usp.toString();
  const { data, error, mutate } = useSWR(userId && projectId ? `/timesheets/${userId}/user?${queryString}` : null);

  return {
    timesheets: data?.data,
    pagination: data?.pagination,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
