import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useSession } from '../dal';
import DDChat from './chat';

declare global {
  interface Window {
    chat: any;
  }
}

interface ChatContextType {
  chatUnread: Record<string, any>;
  chatReady: boolean;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

const useChatContext = () => {
  const context = useContext(ChatContext);

  if (context === undefined) {
    throw new Error('useChatContext was used outside of its Provider');
  }

  return context;
};

const ChatContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [chatUnread, setChatUnread] = useState<any>({});
  const [chatReady, setChatReady] = useState<boolean>(false);
  const { session } = useSession();

  const onNotify = (data: any) => {
    setChatUnread((prevState: any) => ({ ...prevState, [data.chatView]: data }));
  };

  const onLoad = (data: any) => {
    setChatUnread(data);
    setChatReady(true);
  };

  useEffect(() => {
    if (session?.token && !window.chat) {
      window.chat = new DDChat({
        el: 'ddc-chat-internal',
        chatId: process.env.REACT_APP_CHAT_ID,
        token: session.token,
        name: session.name,
        picture: session.picture,
        onNotify,
        onLoad,
        iFrameURL: 'https://ddc-chat-test-eipwv.ondigitalocean.app/',
      });
    } else if (window.chat) {
      window.chat.setChannel({ token: session?.token });
    }
  }, [session]);

  return (
    <ChatContext.Provider
      value={{
        chatUnread,
        chatReady,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export { ChatContextProvider, useChatContext };
