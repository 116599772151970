import { DatePicker, Input, message, Modal, Typography } from 'antd';
import axios from 'axios';
import { Moment } from 'moment';
import { useState } from 'react';
import { useProjectTasks } from '../../dal/useProjectTasks';
import { useSettings } from '../../dal/useSettings';
import { PhaseTasksSetting } from '../../pages/settingsPhases';
import TemplatePhases from '../../pages/settingsPhases/TemplatePhases';

type Props = { projectId: string; template: string; onClose: () => void; showOverrideConfirmation: boolean };

const PhaseConfirmation = ({ projectId, template, onClose, showOverrideConfirmation }: Props) => {
  const { mutate: mutateProjectTasks } = useProjectTasks(projectId);
  const { settings } = useSettings<PhaseTasksSetting>('phases_tasks');
  const [danger, setDanger] = useState('');
  const [startDate, setStartDate] = useState<Moment | null>(null);

  const selectedTemplate = settings?.templates.filter(t => t.name === template)[0];

  const bootstrapFromTemplate = async () => {
    if (showOverrideConfirmation && danger !== 'danger') {
      message.error('Please type "danger" in input');
      return;
    }

    await axios.post(
      `/tasks-phases/${projectId}/template`,
      { template },
      { params: { override: showOverrideConfirmation, startDate: startDate?.toString().trim() } },
    );
    await mutateProjectTasks(null, true);
    onClose();
  };

  return (
    <Modal title="Are you sure?" open={true} onCancel={onClose} onOk={bootstrapFromTemplate} width={1000}>
      <h3>Do you want to create phases and tasks as visible below?</h3>
      {selectedTemplate && (
        <>
          <Typography style={{ marginTop: 32 }}>Enter start date</Typography>
          <DatePicker style={{ width: '200px' }} value={startDate} onChange={v => setStartDate(v)} />
          <TemplatePhases phases={selectedTemplate.phases} potentialStartDate={startDate} displayOptions={false} />
        </>
      )}
      {showOverrideConfirmation && (
        <>
          <Typography style={{ color: 'red', marginTop: 32, marginBottom: 8 }}>
            This will delete all existing phases and tasks! If you want to proceed please type word "danger" below to confirm.
          </Typography>
          <Input value={danger} style={{ width: '200px' }} onChange={e => setDanger(e.target.value)} />
        </>
      )}
    </Modal>
  );
};

export default PhaseConfirmation;
