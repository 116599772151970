import { Button, Col, Divider, Row } from 'antd';
import { Project } from '../../../../entities';
import ProjectShort from '../../../components/projectShort';
import { DashboardPages, ISelectedPage } from '../types/types';
import { ReactComponent as AccordionIcon } from '../../../static/images/accordion-icon.svg';
import React from 'react';
import { ChatChannel } from '../../../entities/chat';
import { CHANNEL_TYPES } from '../../../constants';
import ChannelMenuItem from './ChannelMenuItem';
import { UserWithPermissions } from '../../../dal';

interface ShortProjectViewProps {
  session?: UserWithPermissions;
  projects: Project[];
  chatChannels: ChatChannel[];
  chatUnread: Record<string, any>;
  selectedPage: ISelectedPage<any>;
  projectAccordionState: boolean;
  isError: boolean;
  isLoading: boolean;
  isCreatingNewChannel: boolean;
  onSelectPage: (type: DashboardPages, data: ChatChannel | Project) => void;
  onCloseChannelList: () => void;
}

const ShortProjectView = ({
  session,
  projects,
  chatChannels,
  chatUnread,
  selectedPage,
  projectAccordionState,
  isError,
  isLoading,
  isCreatingNewChannel,
  onSelectPage,
  onCloseChannelList,
}: ShortProjectViewProps) => {
  const [directMessagesAccordionState, setDirectMessagesAccordionState] = React.useState(
    localStorage.getItem('direct-accordion') ? localStorage.getItem('direct-accordion') === 'true' : true,
  );

  const [groupsAccordionState, setGroupsAccordionState] = React.useState(localStorage.getItem('groups-accordion') === 'true');

  const displayProjects = projectAccordionState ? projects : projects.slice(0, 9);
  const selectedMenu = selectedPage.type === DashboardPages.CHAT && selectedPage.data.id;

  return (
    <>
      {displayProjects.map(project => (
        <ProjectShort
          key={project.id}
          project={project}
          chatUnread={chatUnread}
          isSelected={selectedPage.type === DashboardPages.PROJECT && selectedPage.data.id === project.id}
          className={`mx-[8px] project-item ${
            selectedPage.type === DashboardPages.PROJECT && selectedPage.data.id === project.id ? '!text-white selected-item' : '!text-[#818181]'
          }`}
          handleClickSelect={onSelectPage}
        />
      ))}

      {isError && !isLoading ? (
        <Row className="!cursor-default pr-[6px] mx-[8px]">
          <Col flex="auto">
            <span className="cursor-pointer !m-0 font-[Proxima Nova] font-[700] text-[#585858] text-[21px] !pl-[8px] text-ellipsis overflow-hidden whitespace-nowrap">
              Sorry, chat server is unavailable.
            </span>
          </Col>
        </Row>
      ) : null}

      {!isError && !isLoading && (
        <>
          <Divider className="bg-[#ececec] bg-opacity-0 !m-0 !mb-3 !mt-3" />
          <Row className="pr-[6px] flex-nowrap mb-[6px] mx-[8px]">
            <Col
              flex="auto"
              className="cursor-pointer overflow-hidden text-ellipsis font-[Proxima Nova] font-[700] text-[#1D1D1D] text-[21px] whitespace-nowrap !pl-[8px]"
              onClick={() => {
                localStorage.setItem('direct-accordion', !directMessagesAccordionState + '');
                setDirectMessagesAccordionState(!directMessagesAccordionState);
              }}
            >
              Direct Messages
            </Col>
            <Col>
              <Button
                className="button-without-bg"
                type="text"
                icon={<AccordionIcon className={`transition-all rotate-180 ${directMessagesAccordionState && '!rotate-[-180]'}`} />}
                onClick={() => {
                  localStorage.setItem('direct-accordion', !directMessagesAccordionState + '');
                  setDirectMessagesAccordionState(!directMessagesAccordionState);
                }}
              />
            </Col>
          </Row>
          {chatChannels
            .filter(
              (channel: ChatChannel) =>
                channel.users &&
                channel.type === CHANNEL_TYPES.INDIVIDUAL &&
                (selectedMenu === channel.id || directMessagesAccordionState || channel.unread || channel.manualUnread),
            )
            .map((channel: ChatChannel) => (
              <ChannelMenuItem
                key={channel.id}
                channel={channel}
                selected={selectedMenu === channel.id}
                picture={channel.users.find(data => data.user.id !== session?.id)?.user.picture}
                countBadgePropName="unread"
                isCreatingNewChannel={isCreatingNewChannel}
                onSelect={() => onSelectPage(DashboardPages.CHAT, channel)}
                onCloseChannelList={onCloseChannelList}
              />
            ))}
          {chatChannels
            .filter(
              (channel: ChatChannel) =>
                channel.users &&
                channel.type === CHANNEL_TYPES.PRIVATE &&
                !channel.isNameEdited &&
                (selectedMenu === channel.id || directMessagesAccordionState || channel.unread || channel.manualUnread),
            )
            .map((channel: ChatChannel) => (
              <ChannelMenuItem
                key={channel.id}
                channel={channel}
                selected={selectedMenu === channel.id}
                isCreatingNewChannel={isCreatingNewChannel}
                countBadgePropName="unread"
                onSelect={() => onSelectPage(DashboardPages.CHAT, channel)}
                onCloseChannelList={onCloseChannelList}
              />
            ))}
          {session && (
            <>
              <Divider className="bg-[#ECECEC] bg-opacity-0 !m-0 !mb-3 !mt-3" />
              <Row className="!cursor-default pr-[6px] mx-[8px] mb-[8px]">
                <Col
                  flex="auto"
                  className="cursor-pointer overflow-hidden text-ellipsis font-[Proxima Nova] font-[700] text-[#1D1D1D] text-[21px] whitespace-nowrap !pl-[8px]"
                  onClick={() => {
                    localStorage.setItem('groups-accordion', !groupsAccordionState + '');
                    setGroupsAccordionState(!groupsAccordionState);
                  }}
                >
                  Channels
                </Col>
                <Col>
                  <Button
                    className="button-without-bg"
                    type="text"
                    icon={<AccordionIcon className={` transition-all rotate-180 ${groupsAccordionState && '!rotate-[-180]'}`} />}
                    onClick={() => {
                      localStorage.setItem('groups-accordion', !groupsAccordionState + '');
                      setGroupsAccordionState(!groupsAccordionState);
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          {chatChannels
            .filter(
              (channel: ChatChannel) =>
                channel.users &&
                channel.type === CHANNEL_TYPES.PRIVATE &&
                channel.isNameEdited &&
                (selectedMenu === channel.id || groupsAccordionState || channel.unread || channel.manualUnread),
            )
            .map((channel: ChatChannel) => (
              <ChannelMenuItem
                key={channel.id}
                channel={channel}
                selected={selectedMenu === channel.id}
                isCreatingNewChannel={isCreatingNewChannel}
                countBadgePropName="unreadMentions"
                onSelect={() => onSelectPage(DashboardPages.CHAT, channel)}
                onCloseChannelList={onCloseChannelList}
              />
            ))}
        </>
      )}
    </>
  );
};

export default ShortProjectView;
