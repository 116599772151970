import { Col, Row, Divider } from 'antd';
import { displayHoursFromNumber, getTotalHours, getTotalAmount, formatCurrency, formatDateTime } from '../../common/utils';
import { Timesheet, User, UserRates } from '../../entities';
import { Column } from '@ant-design/plots';
import { useState } from 'react';
import ProjectSelector from '../project/ProjectSelector';

type Props = {
  timesheets: Timesheet;
  rates: UserRates | User;
  activeDay: string;
};
type Data = {
  type: string;
  sales: number;
};
const AntdColumn: any = Column;

const TimesheetMonth = ({ timesheets, rates, activeDay }: Props) => {
  const [projectId, setProjectId] = useState<string | undefined | null>(undefined);
  const timesheetsData = timesheets
    .map(t => {
      t.amount =
        t.hours > 0 && rates?.rateValue && rates.rateType === 'hourly' ? t.hours * (t.customRate || rates.rateValue) : t.fixedCost ? t.fixedCost : 0;
      return t;
    })
    .filter(t => (projectId ? t.project.id === projectId : t));

  const totalHours = getTotalHours(timesheetsData);
  const totalAmount = getTotalAmount(timesheetsData, rates);
  const hasCustomSpents = timesheetsData.find(t => t.customRate > 0 || t.fixedCost !== 0);
  const days = new Date(Number(formatDateTime(activeDay, 'YYYY')), Number(formatDateTime(activeDay, 'M')), 0).getDate();
  const getTotalHoursFromTimesheet = (timesheets: Timesheet, day: number): number => {
    let total = 0;
    for (let timesheet of timesheets) {
      let dayFromDate = Number(timesheet.entryDate.toString().split('-')[2].replace(/^0+/, ''));
      if (dayFromDate === day) {
        total += timesheet.hours;
      }
    }
    return total;
  };

  let data: Data[] = [];
  while (data.length !== days) {
    data[data.length] = {
      type: (data.length + 1).toString(),
      sales: getTotalHoursFromTimesheet(timesheetsData, data.length + 1),
    };
  }

  const config = {
    data,
    xField: 'type',
    yField: 'sales',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.8,
      },
      content: ({ sales }: Data) => displayHoursFromNumber(sales),
    },
    height: 200,
  };

  return (
    <>
      <div style={{ margin: '10px 0 30px 0' }}>
        <h4>Filter by project</h4>
        <ProjectSelector value={projectId} onChange={(value: string) => setProjectId(value)} style={{ width: '300px' }} />
      </div>
      <div>
        <AntdColumn {...config} />
      </div>
      <br />
      <Row>
        <Col span={2}>
          <h4>Date</h4>
        </Col>
        <Col span={5}>
          <h4>Project</h4>
        </Col>
        <Col span={3}>
          <h4>Task</h4>
        </Col>
        <Col span={3}>
          <h4>Role</h4>
        </Col>
        <Col span={hasCustomSpents ? 4 : 7}>
          <h4>Description</h4>
        </Col>
        {hasCustomSpents && (
          <Col span={3} className="timesheet-col-left timesheet-col-left--no-margin">
            <h4>Spent</h4>
          </Col>
        )}
        <Col span={2} className="timesheet-col-left timesheet-col-left--no-margin">
          <h4>Hours</h4>
        </Col>
        <Col span={2} className="timesheet-col-center">
          <h4>Amount</h4>
        </Col>
      </Row>
      <Divider style={{ marginTop: 5 }} />
      {timesheetsData.map(t => (
        <Row className="timesheet-row" key={t.id}>
          <Col span={2} className="timesheet-col-left timesheet-col-left--no-margin">
            {t.entryDate.toString()}
          </Col>
          <Col span={5} className="timesheet-col-left timesheet-col-left--no-margin">
            <strong>
              {t.project?.client?.name || <i>No client</i>} - {t.project?.name || <i>No project name</i>}
            </strong>
          </Col>
          <Col span={3} className="timesheet-col-left timesheet-col-left--no-margin">
            {t.task ? t.task.name : 'No task selected'}
          </Col>
          <Col span={3} className="timesheet-col-left timesheet-col-left--no-margin">
            {t.category}
          </Col>
          <Col span={hasCustomSpents ? 4 : 7} className="timesheet-col-left timesheet-col-left--no-margin">
            {t.description}
          </Col>
          {hasCustomSpents && (
            <Col span={3} className="timesheet-col-left timesheet-col-left--no-margin">
              {t.fixedCost
                ? 'Fixed cost'
                : t.customRate
                ? t.customRate === rates?.urgentRateValue
                  ? 'Urgent hours'
                  : 'Custom rate: ' + formatCurrency(t.customRate)
                : 'Hours'}
            </Col>
          )}
          <Col span={2} className="timesheet-col-left timesheet-col-left--no-margin">
            {t.hours > 0 && <h3>{displayHoursFromNumber(t.hours)}</h3>}
          </Col>
          <Col span={2} className="timesheet-col-center">
            {t.amount > 0 && <h3>{formatCurrency(t.amount)}</h3>}
          </Col>
        </Row>
      ))}
      {totalHours > 0 && (
        <>
          <Divider />
          <Row className="timesheet-row">
            <Col span={20}>
              <h3>Total</h3>
            </Col>
            <Col span={2} className="timesheet-col-left timesheet-col-left--no-margin">
              <h3>{displayHoursFromNumber(totalHours)}</h3>
            </Col>
            <Col span={2} className="timesheet-col-center">
              <h3>{formatCurrency(totalAmount)}</h3>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default TimesheetMonth;
