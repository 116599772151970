import { Input, Modal, Spin, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { User } from '../../chat/entities';
import UserFinder from '../../chat/components/chat/userFinder';
import React from 'react';

interface Props {
  modalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  users;
  session?: User;
  onAddUserToChannel: React.Dispatch<React.SetStateAction<string[]>>;
  newChannelMembers;
  createNewChannel;
  chatChannels;
  handleChannel;
  loadUsers;
}

const AddChannelForm = ({
  modalOpen,
  loadUsers,
  setModalOpen,
  users,
  onAddUserToChannel,
  session,
  createNewChannel,
  newChannelMembers,
  chatChannels,
  handleChannel,
}: Props) => {
  const [form] = useForm();

  React.useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async data => {
    const members = newChannelMembers && newChannelMembers.map(member => users && users.find(user => user.id === member.split('/')[1]));
    createNewChannel({ name: data.name, members: members });

    setModalOpen(false);
  };

  if (!users) return <Spin />;

  return (
    <Modal
      title="New channel"
      open={modalOpen}
      okText="Create channel"
      onOk={() => form.submit()}
      onCancel={() => setModalOpen(false)}
      className="channel-modal"
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item className="custom-form-block" label="Channel name" name="name" rules={[{ required: true, message: 'Channel name is required' }]}>
          <Input height="50px" placeholder="Enter channel name" />
        </Form.Item>
        <Form.Item className="custom-form-block" label="Add members" name="client" rules={[{ required: true, message: 'Mermbers is required' }]}>
          <UserFinder
            onChange={onAddUserToChannel}
            options={users.reduce(
              (pV, cV) =>
                cV.id !== session?.id
                  ? [
                      ...pV,
                      {
                        label: (
                          <span key={cV.id}>
                            <img className="my-[.2rem] w-[24px] h-[24px] rounded-full" src={cV.picture} alt="" /> {cV.name}
                          </span>
                        ),
                        value: cV.name + '/' + cV.id,
                      },
                    ]
                  : pV,
              [],
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddChannelForm;
