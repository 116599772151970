import { Tabs, TabsProps } from 'antd';
import './SingleTab.css';

export const SingleTab = (props: TabsProps) => {
  return (
    <Tabs defaultActiveKey="1" {...props} className="single-tab">
      <Tabs.TabPane tab={props.title} key="1">
        {props.children}
      </Tabs.TabPane>
    </Tabs>
  );
};
