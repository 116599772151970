import { Button, Popconfirm, Select, Space, Typography } from 'antd';
import moment, { Moment } from 'moment';
import React, { useCallback } from 'react';
import { Phase } from '.';
import { datePickerFormat } from '../../constants';
import PhaseTasks from './PhaseTasks';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

interface Props {
  phases: Phase[];
  onChange?: (ps: Phase[]) => void;
  displayOptions?: boolean;
  potentialStartDate?: Moment | null;
}

const TemplatePhases = ({ phases, onChange, potentialStartDate, displayOptions = true }: Props) => {
  const changePhaseTasks = (name: string, tasks: string[]) => {
    const objIndex = phases.findIndex(i => i.name === name);
    phases[objIndex].tasks = tasks;
    onChange && onChange(phases);
  };

  const deletePhase = (name: string) => {
    onChange && onChange(phases.filter(p => p.name !== name));
  };

  const move = (name: string, up: boolean) => {
    const newPhases = [...phases];
    const fromIndex = newPhases.findIndex(p => p.name === name);
    const toIndex = up ? fromIndex - 1 : fromIndex + 1;

    const element = newPhases.splice(fromIndex, 1)[0];
    newPhases.splice(toIndex, 0, element);
    onChange && onChange(newPhases);
  };

  const handleLengthChange = (value: number, name: string) => {
    const objIndex = phases.findIndex(i => i.name === name);
    phases[objIndex].lengthInWeeks = value;
    onChange && onChange(phases);
  };

  const updatePhaseName = useCallback((phase: Phase, newName: string) => {
    phase.name = newName;
    onChange && onChange(phases);
  }, [onChange, phases]);

  const getStartDateForPhase = (name: string): string => {
    let date;
    const findIndex = phases.findIndex(p => p.name === name);
    if (findIndex === 0) {
      date = potentialStartDate;
    } else {
      const weeks = phases.slice(0, findIndex).reduce((prev, curr) => {
        return prev + curr.lengthInWeeks;
      }, 0);
      date = moment(potentialStartDate).add(weeks, 'weeks');
    }

    return moment(date).format(datePickerFormat);
  };

  const getEndDateForPhase = (name: string) => {
    let date;
    const findIndex = phases.findIndex(p => p.name === name);

    const weeks = phases.slice(0, findIndex + 1).reduce((prev, curr) => {
      return prev + curr.lengthInWeeks;
    }, 0);
    date = moment(potentialStartDate).add(weeks, 'weeks');

    return moment(date).format(datePickerFormat);
  };

  return (
    <>
      {phases.map((p, i) => (
        <React.Fragment key={i}>
          <div className="tasks-table-header">
            <Typography.Text editable={{
              onChange: (t) => updatePhaseName(p, t),
              triggerType: ['text', 'icon']
            }}>{p.name}</Typography.Text>
            <span>
              {potentialStartDate && (
                <>
                  Start date: <strong>{getStartDateForPhase(p.name)}</strong> &bull; End date: <strong>{getEndDateForPhase(p.name)}</strong>
                </>
              )}
            </span>

            {displayOptions && (
              <Space>
                {i !== 0 && (
                  <Button icon={<ArrowUpOutlined />} size="small" onClick={() => move(p.name, true)}>
                    Move up
                  </Button>
                )}
                {i !== phases.length - 1 && (
                  <Button icon={<ArrowDownOutlined />} size="small" onClick={() => move(p.name, false)}>
                    Move down
                  </Button>
                )}
                <Select
                  placeholder="Phase length in weeks"
                  defaultValue={p.lengthInWeeks}
                  onChange={v => handleLengthChange(v, p.name)}
                  size="small"
                  options={[
                    {
                      value: 1,
                      label: '1 week',
                    },
                    {
                      value: 2,
                      label: '2 weeks',
                    },
                    {
                      value: 3,
                      label: '3 weeks',
                    },
                    {
                      value: 4,
                      label: '4 weeks',
                    },
                    {
                      value: 5,
                      label: '5 weeks',
                    },
                    {
                      value: 6,
                      label: '6 weeks',
                    },
                    {
                      value: 7,
                      label: '7 weeks',
                    },
                    {
                      value: 8,
                      label: '8 weeks',
                    },
                  ]}
                />
                <Popconfirm title="Are you sure you want to delete this phase?" onConfirm={() => deletePhase(p.name)} okText="Yes" cancelText="No">
                  <Button danger size="small">
                    Delete phase
                  </Button>
                </Popconfirm>
              </Space>
            )}
          </div>
          <PhaseTasks tasks={p.tasks} onChange={tasks => changePhaseTasks(p.name, tasks)} displayOptions={displayOptions} />
        </React.Fragment>
      ))}
    </>
  );
};

export default TemplatePhases;
