import { Input, Modal, Select, Spin, Form } from 'antd';
import { usePresentationTemplates } from '../../dal';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface Props {
  modalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  client?: string;
}

const AddPresentationForm = ({ modalOpen, setModalOpen, client }: Props) => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { templates } = usePresentationTemplates();

  const onFinish = async (values: { name: string; key: string, templateName: string }) => {
    const result = await axios.post(`/presentations/new`, { name: values.name, key: values.key, templateName: values.templateName, data: {} });
    // await mutate(null, true);
    // await mutateClient(null, true);
    setModalOpen(false);
    navigate(`/presentations/${result.data?.data?.id}`);
  };

  if (!templates) return <Spin />;

  return (
    <Modal title="Create presentation" open={modalOpen} onOk={() => form.submit()} onCancel={() => setModalOpen(false)}>
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} form={form} onFinish={onFinish}>
        <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
          <Input placeholder="Enter new presentation name" />
        </Form.Item>
        <Form.Item label="URL key" name="key" rules={[{ required: true, message: 'URL key is required' }]}>
          <Input placeholder="Enter new uniq URL" />
        </Form.Item>
        <Form.Item label="Template" name="templateName" initialValue={'default'} rules={[{ required: true, message: 'Template is required' }]}>
          <Select placeholder="Select template">
            {templates.map(t => (
              <Select.Option key={t} value={t}>
                {t}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddPresentationForm;
