import useSWR from 'swr';
import { FetchResult, Timesheet } from '../entities';

type Result = FetchResult & { timesheets: Timesheet };

export function useProjectsTimesheets(id?: string): Result {
  const { data, error, mutate } = useSWR(id ? `/projects/${id}/timesheets` : null);

  return {
    timesheets: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
