import { useCallback, useEffect, useState } from 'react';
import './RecentSearches.css';

const storageKey = 'recent_searches';

type Props = {
  query: string;
  setQuery: (q: string) => void;
};

const RecentSearches = ({ query, setQuery }: Props) => {
  const recentFromStorage = localStorage.getItem(storageKey);
  const [recent, setRecent] = useState<Array<string>>(!!recentFromStorage ? JSON.parse(recentFromStorage) : []);

  const saveToStorage = useCallback((data: string) => {
    localStorage.setItem(storageKey, data);
  }, []);

  useEffect(() => {
    let newRecentSearches;
    if (!!recentFromStorage && !!query) {
      const parsedRecent = JSON.parse(recentFromStorage);
      if (!parsedRecent.includes(query)) {
        if (parsedRecent.length >= 4) {
          const results = [query, ...parsedRecent];
          results.pop();
          newRecentSearches = results;
        } else {
          newRecentSearches = [query, ...parsedRecent];
        }
      }
    } else if (!!query) {
      newRecentSearches = [query];
    }

    if (newRecentSearches) {
      saveToStorage(JSON.stringify(newRecentSearches));
      setRecent(newRecentSearches);
    }
  }, [query, saveToStorage, recentFromStorage]);

  if (!!query) return null;

  return (
    <div>
      {!!recent && recent.length > 0 && <p className="searches-header">Recent searches</p>}

      <ul className="recent-searches-list">
        {!!recent &&
          recent.map((r, i) => (
            <li key={i} onClick={() => setQuery(r)}>
              {r}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default RecentSearches;
