import { useEffect, useRef } from 'react';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import './SearchInput.css';

type Props = {
  query: string;
  setQuery: (q: string) => void;
  onClose: () => void;
};

const SearchInput = ({ query, setQuery, onClose }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="modal-search-input-wrapper">
      <div>
        <SearchOutlined style={{ fontSize: '24px', color: 'rgba(255, 255, 255, 0.4)' }} />
        <input
          ref={inputRef}
          value={query}
          onChange={e => setQuery(e.target.value)}
          className="modal-search-input"
          placeholder="Search for projects, tasks, people..."
        />
      </div>
      <CloseOutlined onClick={onClose} style={{ fontSize: '16px', color: 'rgba(255, 255, 255, 0.4)', cursor: 'pointer' }} />
    </div>
  );
};

export default SearchInput;
