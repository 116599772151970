import axios from 'axios';
import TasksTableContainer from '../../components/tasks/TasksTableContainer';
import { useUserTasks } from '../../dal/useUserTasks';
import { Task } from '../../entities';

type Props = {
  userId: number;
};

const UserTasks = ({ userId }: Props) => {
  const { tasks, mutate } = useUserTasks(userId);

  const handleTaskChange = async (values: Partial<Task>) => {
    const resp = await axios.put(`/tasks/${values.id}`, { ...values, id: undefined }, { params: { userId } });
    await mutate(resp.data);
  };

  return <TasksTableContainer data={tasks} title="" personal={false} showProjectSortOption={true} userId={userId} onTaskChange={handleTaskChange} />;
};

export default UserTasks;
