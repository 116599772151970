import { message, Switch, Row, Col, Divider } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { User } from '../../entities';
interface Props {
  user: User;
}

const UserTimesheetSwitcher = ({ user }: Props) => {
  const [checked, setChecked] = useState<boolean>(user.requireTimesheet);
  const onChange = async (checked: boolean) => {
    setChecked(checked);
    await axios.patch(`/users/${user.id}`, { requireTimesheet: checked });
    message.success('Saved');
  };

  return (
    <>
      <Divider orientation="left">Timesheet settings</Divider>
      <Row gutter={16} style={{ marginBottom: 24 }}>
        <Col>Require timesheet</Col>
        <Col>
          <Switch checked={checked} onChange={onChange} />
        </Col>
      </Row>
    </>
  );
};

export default UserTimesheetSwitcher;
