import { useState } from "react";
import { Button, Form, message, Select, Switch, Input, Row, Col, Divider, DatePicker, Popconfirm } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { User, UserRates } from '../../entities';
import MoneyInput from '../shared/MoneyInput';
import { datePickerFormat } from '../../constants';
import moment from 'moment';

interface Props {
  user: User;
  rates: UserRates[];
}

interface IObjectKeys {
  [key: string]: string | number | undefined;
}

const UsersRates = ({ user, rates }: Props) => {
  const onFinish = async (values: User) => {
    await axios.patch(`/users/${user.id}`, values);
    message.success('Rate saved');
  };

  // Use existing data from previous format
  if (user.rateValue) {
    rates = [...rates,
    {
      validFrom: '2000-01-01Z',
      validTo: null,
      effectiveDate: '2000-01-01Z',
      rateType: user.rateType,
      rateValue: user.rateValue,
      allowUrgentRate: user.allowUrgentRate,
      urgentRateValue: user.urgentRateValue,
      averageHourlyRate: user.averageHourlyRate,
      rateComment: 'Initial',
    }
    ];
  }

  const [urgentRateField, setUrgentRateField] = useState<IObjectKeys>(rates?.reduce((a, v, i) => ({ ...a, [i]: v.allowUrgentRate }), {}));

  return (
    <>
      <Divider orientation="left">Rates settings</Divider>
      <Form layout="vertical" onFinish={onFinish} initialValues={{
        ...user,
        rates: rates?.map(r => {
          r.effectiveDate = r.effectiveDate ? moment(r.effectiveDate).utc() : undefined;
          return r;
        })
      }}>
        <Row gutter={16}>
          <Col>
            <Form.Item label="Xero ContactName (optional)" name="xeroContactName">
              <Input style={{ width: '240px' }} placeholder={user.name} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Xero Invoice Suffix (optional)" name="xeroInvoiceSuffix">
              <Input
                style={{ width: '100px' }}
                placeholder={user.name
                  .split(' ')
                  .map(n => n[0])
                  .join('')}
              />
            </Form.Item>
          </Col>
          <Col></Col>
          <Col>
            <Form.Item label="Allow Variable Rate" name="allowVariableRate" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Allow Fixed one-off expenses" name="allowFixedCost" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>

        <Form.List name="rates">
          {(fields: any, { add, remove }) => (
            <>
              <Row>
                <Col span="20">
                  <Form.Item>
                    <Button type="dashed" onClick={() => add(undefined, 0)} block icon={<PlusOutlined />}>
                      Add Rate
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              {fields.length ? (
                <>
                  <Row>
                    <Col style={{ width: '160px' }}>Effective Date</Col>
                    <Col style={{ width: '160px' }}>Rate Type</Col>
                    <Col style={{ width: '140px' }}>Value</Col>
                    <Col style={{ width: '140px' }}>Avg Hourly Rate (for calculation purposes)</Col>
                    <Col style={{ width: '210px' }}>Allow Urgent Rate</Col>
                    <Col span="5">Comment</Col>
                  </Row>
                  <Row>
                    <Col span={20}><Divider style={{ marginTop: '20px' }} /></Col>
                  </Row>
                </>
              ) : ('')}
              {fields.map((field: any) => (
                <Row key={field.key} gutter={20}>
                  <Col>
                    <Form.Item {...field} name={[field.name, 'effectiveDate']} rules={[{ required: true, message: 'Field is required' }]}>
                      <DatePicker format={datePickerFormat} defaultPickerValue={moment().utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item {...field} name={[field.name, 'rateType']} rules={[{ required: true, message: 'Field is required' }]}>
                      <Select style={{ width: '140px' }}>
                        <Select.Option value="hourly">Hourly rate</Select.Option>
                        <Select.Option value="monthly">Monthly rate</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item {...field} name={[field.name, 'rateValue']} rules={[{ required: true, message: 'Field is required' }]}>
                      <MoneyInput />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item {...field} name={[field.name, 'averageHourlyRate']} rules={[{ required: true, message: 'Field is required' }]}>
                      <MoneyInput />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item {...field} name={[field.name, 'allowUrgentRate']} valuePropName="checked">
                      <Switch onChange={checked => setUrgentRateField({ ...urgentRateField, [field.key]: checked })} />
                    </Form.Item>
                  </Col>
                  <Col style={{ width: '145px' }}>
                    {urgentRateField[field.key] ? (
                      <Form.Item {...field} name={[field.name, 'urgentRateValue']} rules={[{ required: true, message: 'Field is required' }]}>
                        <MoneyInput />
                      </Form.Item>
                    ) : <></>}
                  </Col>
                  <Col span={6}>
                    <Form.Item {...field} name={[field.name, 'rateComment']}>
                      <Input style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span="1">
                    <Popconfirm title="Are you sure to delete this rate?" onConfirm={() => remove(field.name)} okText="Yes" cancelText="No">
                      <Button type="text" icon={<MinusCircleOutlined />} />
                    </Popconfirm>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List>
        <br />
        <Row gutter={16}>
          <Col>
            <Form.Item label="Notes" name="rateNotes">
              <Input.TextArea style={{ width: '480px' }} rows={6} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col>
            <Form.Item>
              <Button htmlType="submit">Save</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default UsersRates;
