import { Badge, Col, Row } from 'antd';
import React from 'react';
import { Project, ProjectStatus } from '../../../entities';

import './index.scss';
import { DashboardPages } from '../../pages/dashboard/types/types';

interface IProps {
  chatUnread: Record<string, any>;
  project: Project;
  isSelected?: boolean;
  className?: string;
  handleClickSelect: (type: DashboardPages, data: Project) => void;
}

type IProjectColorByStatus = {
  [key in ProjectStatus]: string;
};

const projectColorByStatus: IProjectColorByStatus = {
  active: '#04C19C',
  finished: '#858b9b',
  draft: '#ffba00'
}

const ProjectAdvanced = (props: IProps) => {
  const { chatUnread, project, className, handleClickSelect, isSelected } = props;
  return (
    <div className={`cursor-pointer ${className}`} onClick={() => handleClickSelect(DashboardPages.PROJECT, project)}>
      <div className="proj-adv-border"></div>
      <Row wrap={false} align="middle">
        <Col className="flex items-center">
          <div style={{background: projectColorByStatus[project.status]}} className={`rounded-full w-[8px] h-[8px]`}></div>
        </Col>
        <Col flex="auto" className="ml-[4px] chat-tab-channel">
        <span className={`text-ellipsis text-sm font-[Proxima Nova] font-[600] whitespace-nowrap ${chatUnread[project.id]?.unread && !isSelected && 'text-black'}`}>{project.client?.name} • {project.name}</span>
        </Col>
        <Col flex="none" className="tab-badges">
          {chatUnread[project.id] && !chatUnread[project.id].unreadMentions && (
            <Badge dot={Boolean(chatUnread[project.id].manualUnread)} style={{ transform: 'none', boxShadow: 'none' }} />
          )}

          {chatUnread[project.id]?.unreadMentions > 0 && (
            <Badge count={chatUnread[project.id]?.unreadMentions} style={{ transform: 'none', boxShadow: 'none' }} />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <span className={`adv-project-subtitle text-ellipsis text-[14px] text-[#959AB2] font-[Proxima Nova] font-[400] opacity-[0.83] pl-3`}>{project.autoCode} • <span className=' capitalize'>{project.phase}</span></span>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectAdvanced;
