import { Task, Ticket } from '../../entities';
import { Spin } from 'antd';
import { useTicketTasks } from '../../dal/useTicketTasks';
import TasksTableContainer from '../tasks/TasksTableContainer';
import axios from 'axios';

interface Props {
  ticket: Ticket;
}

const TicketTasks = ({ ticket }: Props) => {
  const { tasks, mutate } = useTicketTasks(ticket.id);

  const handleTaskChange = async (values: Partial<Task>) => {
    const resp = await axios.put(`/tasks/${values.id}`, { ...values, id: undefined }, { params: { ticketId: ticket.id } });
    await mutate(resp.data);
  };

  if (!tasks) {
    return <Spin />;
  }

  return (
    <TasksTableContainer
      data={tasks}
      initialProjectId={ticket.project.id}
      initialTicketId={ticket.id}
      title="Tasks"
      personal={false}
      onTaskChange={handleTaskChange}
    />
  );
};

export default TicketTasks;
