import { PageHeader, Col, Table, message, Button, Space, Popconfirm } from 'antd';
import { FilePdfOutlined, FileWordOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { SingleTab } from '../../components/shared/SingleTab';
import { useFiles } from '../../dal/useFiles';
import Uploader from '../../components/filesManager/Uploader';
import { FilesPermissions } from '../../common/usePermissions/permissions';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import axios from 'axios';

export const copyToClipboard = async (file: string) => {
  await navigator.clipboard.writeText(file);
  message.success('File URL copied to clipboard');
};

const FilesManager = () => {
  const { hasPermission } = usePermissions();
  const { files = [], mutate } = useFiles();
  const filesData = files.map(file => ({ id: file, name: file }));

  const deleteFile = async (file: string) => {
    await axios.post(`/files/delete`, { file });
    await mutate();
    message.success('File deleted');
  };

  return (
    <div className='flex flex-col h-full'>
      <PageHeader title="Files manager" />
      <div className="ant-space-vertical">
        <Col>
          <div className="custom-container">
            <SingleTab title="Files">
              <Table dataSource={filesData} size="small" rowKey="id" pagination={false}>
                <Table.Column
                  key="preview"
                  dataIndex="name"
                  title="Preview"
                  render={file => {
                    if (/jpeg|jpg|png|webp|gif/.test(file)) {
                      return <img src={file} alt={file} height="200" />;
                    }
                    if (/pdf/.test(file)) {
                      return <FilePdfOutlined style={{ fontSize: 50 }} />;
                    }
                    if (/doc|docx|odt|txt/.test(file)) {
                      return <FileWordOutlined style={{ fontSize: 50 }} />;
                    }
                    return <>Unknown file type</>;
                  }}
                />
                <Table.Column
                  key="options"
                  dataIndex="name"
                  title="Options"
                  render={file => (
                    <Space>
                      <Button onClick={() => copyToClipboard(file)} icon={<CopyOutlined />} />
                      <Popconfirm title="Do you want to remove this file?" onConfirm={() => deleteFile(file)}>
                        <Button icon={<DeleteOutlined />} danger />
                      </Popconfirm>
                    </Space>
                  )}
                />
              </Table>
            </SingleTab>
          </div>
        </Col>
        {hasPermission(FilesPermissions.FILES_WRITE) && (
          <Col>
            <div className="custom-container">
              <SingleTab title="Upload">
                <Uploader />
              </SingleTab>
            </div>
          </Col>
        )}
      </div>
    </div>
  );
};

export default FilesManager;
