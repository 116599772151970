import { useState } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { Task } from '../../entities';
import { datePickerFormat } from '../../constants';

interface Props {
  task: Task;
  onTaskChange: (data: Partial<Task>) => Promise<void>;
}

const TaskDueDate = ({ task, onTaskChange }: Props) => {
  const [date, setDate] = useState<any>(task.dueDate ? moment(task.dueDate) : undefined);

  const handleChange = (value: any) => {
    setDate(value);
    onTaskChange({ id: task.id, dueDate: new Date(value) });
  };

  return <DatePicker format={datePickerFormat} value={date} onChange={handleChange} style={{ width: '135px' }} />;
};

export default TaskDueDate;
