import React from 'react';
import { Modal } from 'antd';
import { User } from '../../../entities';
import { UserChannel } from '../../../entities/chat';
import UserFinder from '../userFinder';

interface Props {
  user?: User;
  allUsers: User[];
  members: UserChannel[];
  channelId: string;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  onAddUserToChannel;
  handleAddNewMembers;
}

const ChannelEditModal: React.FunctionComponent<Props> = ({
  user,
  allUsers,
  members,
  channelId,
  isModalOpen,
  setIsModalOpen,
  onAddUserToChannel,
  handleAddNewMembers,
}) => {
  const handleOk = () => {
    handleAddNewMembers();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal title="Add user" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <UserFinder
        onChange={onAddUserToChannel}
        options={(allUsers as any).reduce(
          (pV, cV) =>
            !members?.find(mUser => mUser.user.id === cV.id)
              ? [
                  ...pV,
                  {
                    label: (
                      <span key={cV.id}>
                        <img className="my-[.2rem] w-5 h-5 rounded-full" src={cV.picture} alt="" /> {cV.name}
                      </span>
                    ),
                    value: cV.name + '/' + cV.id,
                  },
                ]
              : pV,
          [],
        )}
      />
    </Modal>
  );
};

export default ChannelEditModal;
