import { Card, Form, Button, Row, Col, Select } from 'antd';
import { ProjectPermissions } from '../../common/usePermissions/permissions';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { Project } from '../../entities';
import { projectCategories, projectPhases } from '../../constants';

interface Props {
  initialFormValues: any;
  project: Project;
  submitProject: (values: any) => void;
}

const ProjectDetails = ({ initialFormValues, submitProject, project }: Props) => {
  const { hasPermission } = usePermissions();

  return (
    <Form name="basic" initialValues={initialFormValues} onFinish={submitProject} layout="vertical">
      <Card
        title="Details"
        size="small"
        extra={
          hasPermission(ProjectPermissions.PROJECT_UPDATE) && (
            <Button type="default" htmlType="submit" size="small">
              Save
            </Button>
          )
        }
      >
        <Row gutter={8}>
          <Col span={12}>
            {hasPermission(ProjectPermissions.PROJECT_UPDATE) ? (
              <Form.Item label="Phase" name="phase">
                <Select options={projectPhases} />
              </Form.Item>
            ) : (
              <>Phase: {project.phase}</>
            )}
          </Col>
          <Col span={12}>
            {hasPermission(ProjectPermissions.PROJECT_UPDATE) ? (
              <Form.Item label="Category" name="category">
                <Select options={projectCategories} />
              </Form.Item>
            ) : (
              <>Category: {project.category}</>
            )}
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default ProjectDetails;
