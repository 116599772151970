import { useState } from 'react';
import moment from 'moment';
import { Modal, Button, Space, Popconfirm } from 'antd';
import { getWeekDays, formatDateTime } from '../../common/utils';
import { useUsersTimesheet } from '../../dal/useUsersTimesheet';
import TimesheetTabs from '../Timesheet/TimesheetTabs';
import TimesheetMonth from '../Timesheet/TimesheetMonth';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { datePickerFormat } from '../../constants';
import { useTimesheetModal } from '../Timesheet/useTimesheetModal';
import TimesheetForm from '../Timesheet/TimesheetForm';
import { useUser } from '../../dal/useUser';
import { useUserRates } from '../../dal/useUserRates';

interface Props {
  userId: number;
  canApprove: boolean;
  startDate: string;
  monthlyView: boolean;
  onClose: () => void;
  onApprove: () => void;
  onReject: () => void;
  refreshApprovals: () => void;
}

const ApprovalDetails = ({ userId, onClose, onApprove, onReject, startDate, monthlyView, canApprove, refreshApprovals }: Props) => {
  const { user } = useUser(userId);
  const { rates } = useUserRates(userId, startDate);
  const { timesheets, mutate } = useUsersTimesheet({ userId, startDate, projectId: 'all', monthlyView });
  const [activeDay, setActiveDay] = useState(moment(startDate).format(datePickerFormat));
  const { entryId, modalOpen, openModal, setModalOpen, setEntryId } = useTimesheetModal();

  const refresh = async () => {
    refreshApprovals();
    await mutate();
  };

  const footer = (
    <Space>
      <Button type="text" onClick={onClose}>
        Close
      </Button>
      <Popconfirm title="Do you want to reject this timesheet?" onConfirm={onReject}>
        <Button icon={<CloseOutlined />}>Reject</Button>
      </Popconfirm>
      {canApprove && (
        <Popconfirm title="Do you want to approve this timesheet?" onConfirm={onApprove}>
          <Button icon={<CheckOutlined />} type="primary">
            Approve
          </Button>
        </Popconfirm>
      )}
    </Space>
  );
  return (
    <>
      {user && (
        <Modal
          title={`Timesheet approval ${monthlyView ? `- ${formatDateTime(startDate, 'MMMM')}` : ''} - ${user.name}`}
          open={true}
          onCancel={onClose}
          footer={footer}
          width={1200}
        >
          {timesheets &&
            (monthlyView && rates ? (
              <TimesheetMonth timesheets={timesheets} rates={rates[0]} activeDay={activeDay} />
            ) : (
              <TimesheetTabs
                displayFinancialData={true}
                addEntryEnabled={false}
                activeDay={activeDay}
                onDayChange={value => setActiveDay(moment(value).format(datePickerFormat))}
                timesheets={timesheets}
                days={getWeekDays(startDate)}
                onEditClick={openModal}
              />
            ))}
        </Modal>
      )}
      {modalOpen && entryId && (
        <TimesheetForm
          refreshTimesheets={refresh}
          defaultDate={activeDay}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          entryId={entryId}
          setEntryId={setEntryId}
        />
      )}
    </>
  );
};

export default ApprovalDetails;
