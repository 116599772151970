import useSWR from 'swr';
import { FetchResult, Client } from '../entities';

type Result = FetchResult & { client: Client };

export function useClient(id?: string): Result {
  const { data, error, mutate } = useSWR(id ? `/clients/${id}` : null);

  return {
    client: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
