export const projectPhases = [
  { value: '' },
  { value: 'unassigned' },
  { value: 'discovery' },
  { value: 'design' },
  { value: 'development' },
  { value: 'testing' },
  { value: 'launch' },
  { value: 'warranty' },
];

export const projectCategories = [
  { value: '' },
  { value: 'Website' },
  { value: 'App' },
  { value: 'Maintenance' },
  { value: 'Retainer' },
  { value: 'Photo/Video' },
  { value: 'SEO' },
  { value: 'Internal' },
];

export const datePickerFormat = 'll';

// min width for screen types
export const RESPONSIVE_CONFIG = {
  DESKTOP: 1200,
  MOBILE: 200,
}