import useSWR from 'swr';
import { FetchResult, User } from '../entities';

type Result = FetchResult & { user: User };

export function useUser(id?: string | number): Result {
  const { data, error, mutate } = useSWR(`/users/${id}`);

  return {
    user: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
