import useSWR from 'swr'
import { FetchResult, TimesheetReportItem } from '../entities';

type Result = FetchResult & { report: TimesheetReportItem[], pagination: any }

export function useTimesheetReport(userId: number | null, currentMonth: string | null): Result {
  const { data, error, mutate } = useSWR(() => userId != null ? `/project-finances/timesheet-report?currentMonth=${currentMonth || ''}&userId=${userId}` : null);

  return {
    report: data?.data,
    pagination: data?.pagination,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}