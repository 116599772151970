import './Check.css';

const Check = ({ isChecked, onClick }: { isChecked: boolean; onClick: any }) => {
  if (!onClick) {
    return (
      <div className={`check-container check-container--non-interctive ${isChecked && 'check-container--checked-non-interactive'}`}>
        <div className="checkmark check-container-animated" />
      </div>
    );
  }

  return (
    <div className={`check-container ${isChecked && 'check-container--checked'}`} onClick={onClick}>
      <div className="checkmark check-container-animated" />
    </div>
  );
};

export default Check;
